import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { Button, Divider, Grid, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { postData, getData } from "../../services/axiosInstance";
import { useNavigate } from "react-router-dom";
import shiperInputData from "../../data.json";
import { yupResolver } from "@hookform/resolvers/yup";
import { openNotificationWithIcon } from "../Layout/Toastify/toastify";

import { shipperValidation } from "../../utils/shipperValidationSchema";

const countries = [
  {
    value: "US",
    label: "US",
  },
  {
    value: "Canada",
    label: "Canada",
  },
];
export default function CompanyDetails({
  shipperId,
  ShipperData,
  fetchShipperData,
}) {
  const navigate = useNavigate();

  const defaultValue = {
    companyName: "",
    doingBusiness: "",
    refName: "",
    industryType: "",
    email: "",
    phone: "",
    fax: "",
    website: "",
    country: "",
    zip: "",
    streetAddress: "",
    city: "",
    state: "",
    typeOfBusiness: "",
    yearsInBusiness: "",
    location: "",
    incorporationState: "",
    fedTaxId: "",
  };
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(shipperValidation),
    mode: "all",
    defaultValues: defaultValue,
  });
  const onSubmitCompanyDetailsHandler = async (values) => {
    // setDisabled(true);
    const payload = { ...values };
    const url = shipperId
      ? `/address/shipper/compnayDetails/${shipperId}`
      : "/address/shipper/add";

    try {
      const response = await postData(url, payload);
      if (response.status === 200) {
        openNotificationWithIcon("success", response?.data?.message);
        if (!shipperId) {
          navigate(`/shipper/edit/${response?.data?.data?.shipperId}`);
        }
      }
    } catch (error) {
      // setDisabled(false);
      openNotificationWithIcon("error", error?.response?.data?.message);
    }
  };
  const { zip, country } = watch();

  useEffect(() => {
    const getData = async () => {
      const response = await fetchShipperData();
      Object.keys(response).forEach((key) => {
        setValue(key, response[key]);
      });
    };
    if (shipperId) {
      getData();
    } else {
      reset();
    }
  }, [shipperId]);

  useEffect(() => {
    async function fetchData() {
      if (zip?.length > 4) {
        // get data from zip code
        const url = `/globalAreaDb/infoWithCountryZip?country_code=${country}&zip=${zip}`;
        try {
          const response = await getData(url);
          if (response.data.data === false) {
            return openNotificationWithIcon("error", "Invalid zipcode.");
          } else {
            setValue("state", response.data.data.admin_name1);
            setValue("city", response.data.data.place_name);
          }
        } catch (error) {
          openNotificationWithIcon("error", "Invalid zipcode.");
        }
      }
    }

    fetchData();
  }, [zip]);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmitCompanyDetailsHandler)}
      pl={1}
      pt={5}
      pb={5}
      sx={{
        // "& .MuiTextField-root": { m: 1, width: "25ch" },
        background: "#fff",
        width: "100%",
      }}
      noValidate
      autoComplete="off"
    >
      <div>
        <Grid container spacing={1} ml={3}>
          <Grid item xs={2.5}>
            <Controller
              name="companyName"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  fullWidth
                  id="outlined-required"
                  label="Company Name"
                  variant="filled"
                  placeholder="Enter Company Name"
                />
              )}
            />
            <p style={{ color: "red" }}>{errors?.companyName?.message}</p>
          </Grid>
          <Grid item xs={2.5}>
            <Controller
              name="doingBusiness"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  id="outlined-required"
                  label="Doing Business"
                  variant="filled"
                  placeholder="Enter Doing Business"
                  fullWidth
                />
              )}
            />
            <p style={{ color: "red" }}>{errors?.doingBusiness?.message}</p>
          </Grid>
          <Grid item xs={2.5}>
            <Controller
              name="refName"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  id="outlined-select-currency"
                  variant="filled"
                  select
                  label="Reference name to use"
                  fullWidth
                >
                  {shiperInputData?.refernece_name_to_use?.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
            <p style={{ color: "red" }}>{errors?.refName?.message}</p>
          </Grid>
          <Grid item xs={2.5}>
            <Controller
              name="industryType"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  id="outlined-select-currency"
                  variant="filled"
                  select
                  label="Industry Type"
                  fullWidth
                >
                  {shiperInputData?.industry_type?.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
            <p style={{ color: "red" }}>{errors?.industryType?.message}</p>
          </Grid>
        </Grid>
      </div>
      <div>
        <Grid container spacing={1} ml={3} mt={3}>
          <Grid item xs={2.5}>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  id="outlined-required"
                  label="Email"
                  variant="filled"
                  placeholder="Enter Company Email"
                  fullWidth
                />
              )}
            />
            <p style={{ color: "red" }}>{errors?.email?.message}</p>
          </Grid>
          <Grid item xs={2.5}>
            <Controller
              name="phone"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  id="outlined-required"
                  label="Phone"
                  variant="filled"
                  placeholder="Enter Company Phone"
                />
              )}
            />
            <p style={{ color: "red" }}>{errors?.phone?.message}</p>
          </Grid>
          <Grid item xs={2.5}>
            <Controller
              name="fax"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  id="outlined-required"
                  label="Fax"
                  variant="filled"
                  placeholder="Enter Company Fax"
                />
              )}
            />
            <p style={{ color: "red" }}>{errors?.fax?.message}</p>
          </Grid>
          <Grid item xs={2.5}>
            <Controller
              name="website"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  id="outlined"
                  label="Website"
                  variant="filled"
                  placeholder="Enter Company Website"
                />
              )}
            />
            <p style={{ color: "red" }}>{errors?.website?.message}</p>
          </Grid>
        </Grid>
      </div>
      <div>
        <Grid container spacing={1} ml={3} mt={3}>
          <Grid item xs={2.5}>
            <Controller
              name="country"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  id="outlined-select-currency"
                  select
                  label="Country"
                  variant="filled"
                  fullWidth
                >
                  {countries.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
            <p style={{ color: "red" }}>{errors?.country?.message}</p>
          </Grid>
          <Grid item xs={2.5}>
            <Controller
              name="zip"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  disabled={country ? false : true}
                  required
                  id="outlined-required"
                  label="Zip"
                  variant="filled"
                  placeholder="Enter Company Zip"
                />
              )}
            />
            <p style={{ color: "red" }}>{errors?.zip?.message}</p>
          </Grid>
          <Grid item xs={2.5}></Grid>
          <Grid item xs={2.5}></Grid>
        </Grid>
      </div>
      <div>
        <Grid container spacing={1} ml={3} mt={3}>
          <Grid item xs={2.5}>
            <Controller
              name="streetAddress"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  disabled={country ? false : true}
                  id="outlined-required"
                  label="Street Address"
                  variant="filled"
                  placeholder="Enter Company Street Address"
                />
              )}
            />
          </Grid>
          <Grid item xs={2.5}>
            <Controller
              name="city"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  disabled
                  id="outlined-required"
                  label="City"
                  variant="filled"
                  placeholder="Enter Company City"
                />
              )}
            />
          </Grid>
          <Grid item xs={2.5}>
            <Controller
              name="state"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  disabled
                  id="outlined-required"
                  label="State"
                  variant="filled"
                  placeholder="Enter Company State"
                />
              )}
            />
          </Grid>
          <Grid item xs={2.5}></Grid>
        </Grid>
      </div>
      <Typography ml={5} mt={3} mb={2}>
        Company Details
      </Typography>
      <Divider />
      <div style={{ marginTop: "15px" }}>
        <Grid container spacing={1} ml={3} mt={3}>
          <Grid item xs={2.5}>
            <Controller
              name="typeOfBusiness"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  id="outlined-select-currency"
                  select
                  label="Type of Business"
                  variant="filled"
                  fullWidth
                >
                  {shiperInputData?.type_of_business?.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
            <p style={{ color: "red" }}>{errors?.typeOfBusiness?.message}</p>
          </Grid>
          <Grid item xs={2.5}>
            <Controller
              name="yearsInBusiness"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  id="outlined-required"
                  label="Years in Business"
                  variant="filled"
                  placeholder="Enter Years in Business"
                />
              )}
            />
            <p style={{ color: "red" }}>{errors?.yearsInBusiness?.message}</p>
          </Grid>
          <Grid item xs={2.5}>
            <Controller
              name="location"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  id="outlined-select-currency"
                  select
                  label="Location"
                  variant="filled"
                  fullWidth
                >
                  {shiperInputData?.location?.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
            <p style={{ color: "red" }}>{errors?.location?.message}</p>
          </Grid>
          <Grid item xs={2.5}>
            <Controller
              name="incorporationState"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  id="outlined-select-currency"
                  select
                  label="Incorporation State"
                  variant="filled"
                  fullWidth
                >
                  {shiperInputData?.incorporation_state?.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
            <p style={{ color: "red" }}>
              {errors?.incorporationState?.message}
            </p>
          </Grid>
        </Grid>
      </div>
      <div>
        <Grid container spacing={1} ml={3} mt={3}>
          <Grid item xs={2.5}>
            <Controller
              name="fedTaxId"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  id="outlined-required"
                  label="Fed Tax Id"
                  variant="filled"
                  placeholder="Enter Fed Tax ID"
                />
              )}
            />
            <p style={{ color: "red" }}>{errors?.fedTaxId?.message}</p>
          </Grid>
          <Grid item xs={2.5}></Grid>
          <Grid item xs={2.5}></Grid>
          <Grid item xs={2.5}></Grid>
        </Grid>
      </div>
      <Button
        type="submit"
        variant="contained"
        sx={{
          marginTop: "10px",
          marginLeft: "40px",
          width: "20%",
        }}
      >
        {!shipperId ? "Save" : "Update"}
      </Button>
    </Box>
  );
}
