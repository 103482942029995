import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Box,
  Breadcrumbs,
  Paper,
  Button,
} from "@mui/material";
import AuthLayout from "../components/Auth_Layout/AuthLayout";
import { Link, useParams } from "react-router-dom";

import { getData } from "../services/axiosInstance";
import DetailTable from "../components/Carrier/DetailTable";
import UpdateCarrierModal from "../components/UpdateCarrierModal/UpdateCarrierModal";

const CarrierDetail = () => {
  const { carrierId } = useParams();

  const [carrierData, setCarrierData] = useState(null);
  const [filteredObj, setFilteredObj] = useState(null);

  const fetchCarrierData = async () => {
    if (carrierId) {
      // get data from zip code
      const url = `/address/carrier/view/${carrierId}`;
      try {
        const response = await getData(url);
        if (response.status === 200) {
          setCarrierData(response.data.data);
          filterObject(response?.data?.data?.allData);
          return response.data.data;
        }
      } catch (error) {
        return {};
      }
    }
  };

  function filterObject(obj) {
    const filteredObject = {};

    // Iterate through the keys of the original object
    for (const key of Object.keys(obj)) {
      const value = obj[key];

      // Check if the value is a string or a number
      if (
        typeof value === "string" ||
        typeof value === "number" ||
        typeof value === "boolean" ||
        typeof value === "null" ||
        typeof value === "undefined"
      ) {
        filteredObject[key] = value;
      }
    }
    setFilteredObj(filteredObject);
    return filteredObject;
  }

  useEffect(() => {
    if (carrierId) {
      fetchCarrierData();
    }
  }, [carrierId]);

  const details = carrierData?.allData || {};
  const filteredObjectData = filteredObj || {};

  const handleClick = (id) => {
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: "smooth" });
  };
  const [openAddModal, setOpenAddModal] = useState(false);

  const openModalFunction = () => {
    setOpenAddModal(true);
  };
  const closeModalFunction = ({ refetch }) => {
    setOpenAddModal(false);
    if (refetch) {
      // fetchShipperLoadData();
    }
  };

  return (
    <AuthLayout>
      {/* main box start  */}
      <Box mt={9} ml={2} mr={2} sx={{ width: "100%" }}>
        {/* breadcrums area  */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Breadcrumbs aria-label="breadcrumb" mt={4} mb={2}>
            <Link underline="hover" color="inherit" href="/carriers">
              Carrier
            </Link>
            <Typography color="text.primary">View</Typography>
          </Breadcrumbs>
          <Button onClick={() => openModalFunction()} variant="contained">
            Update
          </Button>
        </Box>
        {/* main page information area  */}
        <Paper elevation={3}>
          <Box mt={4} sx={{ width: "100%" }}>
            <Grid container spacing={1} ml={1}>
              <Button onClick={() => handleClick("Basic Information")}>
                Basic Information
              </Button>
              {Object.keys(details || [])?.map((value) => {
                if (
                  typeof details[value] == "object" &&
                  details[value] !== null
                ) {
                  return (
                    <Button
                      onClick={() => handleClick(value)}
                    >{`${value}`}</Button>
                  );
                } else {
                  return null;
                }
              })}
            </Grid>
            <Grid
              container
              mt={2}
              px={2}
              spacing={1}
              style={{ height: "550px", overflow: "auto" }}
            >
              {filteredObj && (
                <Grid
                  id={"Basic Information"}
                  mt={3}
                  key={"Basic Information"}
                  item
                  xs={12}
                  sm={12}
                  md={12}
                >
                  <DetailTable
                    data={filteredObjectData}
                    title={"Basic Information"}
                  />
                </Grid>
              )}

              {Object.keys(details || [])
                .filter(
                  (val) =>
                    typeof details[val] == "object" && details[val] !== null
                )
                ?.map((value) => {
                  if (
                    typeof details[value] == "object" &&
                    details[value] !== null
                  ) {
                    // console.log(value);
                    return (
                      <Grid
                        id={value}
                        mt={3}
                        key={value}
                        item
                        xs={12}
                        sm={12}
                        md={12}
                      >
                        <DetailTable data={details[value]} title={value} />
                      </Grid>
                    );
                  }
                })}
            </Grid>
          </Box>
        </Paper>
      </Box>
      <UpdateCarrierModal
        openAddModal={openAddModal}
        closeModalFunction={closeModalFunction}
        carrierId={carrierId}
      />
    </AuthLayout>
  );
};

export default CarrierDetail;
