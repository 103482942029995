import * as Yup from "yup";

const ADD_ADDRESS_VALIDATION_SCHEMA = Yup.object().shape({
  companyName: Yup.string().trim()
    .required("Company Name is required."),
          firstName: Yup.string().trim()
    .required("First Name is required."),
      lastName: Yup.string().trim()
    .required("Last Name is required."),
      email: Yup.string().trim()
    .required("Email is required."),
      phone: Yup.string().trim()
    .required("Phone number is required."),
      streetAddress: Yup.string().trim()
    .required("Street address is required."),
      zipcode: Yup.string().trim()
    .required("Zipcode is required."),
      countryCode: Yup.string().trim()
    .required("Country code is required."),
      city: Yup.string().trim()
    .required("City is required."),
      state: Yup.string().trim()
    .required("State is required."),
      country: Yup.string().trim()
    .required("Country is required."),
    // .email("Please enter the valid email ID"),
  // password: Yup.string()
  //   .matches(
  //     /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])/,
  //     "Uppercase, Lowecase, Special Character, Numeric"
  //   )
  //   .min(8, "Password should be atleast 8 characters")
  //   .max(30, "Password cannot exceed more than 30 characters")
  //   .trim()
  //   .label("Password")
  //   .required("Wrong password! Please enter the correct password"),
});

export { ADD_ADDRESS_VALIDATION_SCHEMA };