
import axios from "axios";

export const apiUrl =
  window.location.protocol === "http:"
    ? "http://localhost:8800/api/"
    : window.location.origin + "/api/";

// Create an axios instance
export const api = axios.create({
  baseURL: apiUrl,
});

// Add a request interceptor to include the token
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


// Function to get data
export const getData = async (url, config = {}) => {
  return await api.get(url, config);
};

// Function to post data
export const postData = async (url, data, config = {}) => {
  return await api.post(url, data, config);
};

// Function to get dummy data
export const getDataDummy = async (url, config = {}) => {
  var dummyApi = axios.create({
    baseURL: "", // replace with your API endpoint
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  dummyApi.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return await dummyApi.get(url, config);
};
