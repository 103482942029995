import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Breadcrumbs, Button, TablePagination } from "@mui/material";
import { getData } from "../services/axiosInstance";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import AuthLayout from "../components/Auth_Layout/AuthLayout";
import { useSelector } from "react-redux";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function Users() {
  const userData = useSelector((state) => state?.user?.data);
  const [listData, setUserList] = useState([]);
  const navigate = useNavigate();
  const openModalFunction = () => {
    navigate("/users/add");
  };
      const getUserList = async () => {
      try {
        const url = "user/view";
        const response = await getData(url);
        if (response.status === 200) {
          setUserList(response?.data?.data);
        }
      } catch (error) {
        console.log({ error });
      }
    };
  useEffect(() => {
    if (
      !(
        userData.roles.includes("superadmin") ||
        userData.roles.includes("admin")
      )
    ) {
      navigate("/");
    }
    getUserList();
  }, []);

  const columns = [
    {
      id: 1,
      title: "S. No.",
    },
    {
      id: 2,
      title: "Name",
    },
    {
      id: 3,
      title: "Email",
    },
    {
      id: 4,
      title: "Email Verified",
    },
    {
      id: 5,
      title: "Role",
    },
    {
      id: 6,
      title: "created At",
    },
    {
      id: 7,
      title: "Action",
    },
  ];
  return (
    <AuthLayout>
      {/* main box start  */}
      <Box mt={9} ml={2} mr={2} sx={{ width: "100%" }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {/* breadcrums area  */}

          <Breadcrumbs aria-label="breadcrumb" mt={4} mb={2}>
            <Link underline="hover" color="inherit" href="/users">
              Users
            </Link>
            <Typography color="text.primary">List</Typography>
          </Breadcrumbs>
          <Button variant="contained" onClick={() => openModalFunction()}>
            Add
          </Button>
        </Box>
        {/* main page information area  */}
        <Paper elevation={3}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: "100%" }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  {columns?.map((col) => {
                    return (
                      <TableCell key={`key-${col.id}`}>{col.title}</TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {listData?.map((row,i) => (
                  <StyledTableRow key={row._id}>
                    <StyledTableCell component="th" scope="row">
                      {i+1}
                    </StyledTableCell>
                    <StyledTableCell>
                      {row.name}
                    </StyledTableCell>
                    <StyledTableCell>{row.email}</StyledTableCell>
                    <StyledTableCell>
                      {row.emailVerified ? "Verified" : "Not Verified"}
                    </StyledTableCell>
                    <StyledTableCell>{row.roles.join(",")}</StyledTableCell>

                    <StyledTableCell>
                      {moment(row.createdAt).format("YYYY-MM-DD HH:mm")}
                    </StyledTableCell>
                    <StyledTableCell>
                      <Button
                        variant="contained"
                        onClick={() => navigate(`/users/edit/${row._id} `)}
                      >
                        Edit
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
            {/* <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={listData.length}
              rowsPerPage={10}
              page={5}
              // onPageChange={handleChangePage}
              // onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
          </TableContainer>
        </Paper>
      </Box>
    </AuthLayout>
  );
}
