import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getData } from "../services/axiosInstance";
// import axioPath from "services/axiosForStore";

export const STATUSES = Object.freeze({
  IDLE: "idle",
  ERROR: "error",
  LOADING: "loading",
});

const initialState = {
  data: {},
  status: STATUSES.IDLE,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser(state, action) {
      state.data = action.payload;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
    resetUserState: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = STATUSES.IDLE;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
  },
});

export const { setUser, setStatus, resetUserState } = userSlice.actions;

export const user = (state) => state.user;

export default userSlice.reducer;

export const fetchUser = createAsyncThunk("user/fetch", async () => {
  const url = "/user/getMyProfile";
  try {
    const response = await getData(url);
    return response.data.data;
  } catch (error) {
    return error;
  }
});
