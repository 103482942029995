import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  styled,
  Grid,
  Paper,
} from "@mui/material";
import { getData } from "../../services/axiosInstance";

import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { openNotificationWithIcon } from "../Layout/Toastify/toastify";
import PopupComponent from "../PdfGenerateComponent/PdfGenerateComponent";
import moment from "moment";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const PdfGenerate = ({
  shipperId,
  shipperLoadId,
  fetchShipperLoadData,
  shipperLoadData,
}) => {
  const [allList, setAllList] = useState(shipperLoadData?.agreements || []);
  const [downloadState, setDownloadState] = useState([]);
  const [generateState, setGenerateState] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [selectedButton, setSelectedButton] = React.useState("");

  const columns = [
    {
      id: 1,
      title: "Title",
    },
    {
      id: 2,
      title: "Last Generated",
    },
    {
      id: 3,
      title: "Action",
    },
  ];

  /**
   * Generate Pdf
   */
  const generatePdf = async (type, rowIndex) => {
    setGenerateState([...generateState, rowIndex]);
    openNotificationWithIcon("info", "Generating PDF file.");

    const url = `/address/shipper/loads/${shipperId}/load/${shipperLoadId}/generateFile/${type}`;
    try {
      const response = await getData(url);
      if (response.status === 200) {
        setGenerateState(generateState?.filter((row) => row !== rowIndex));

        openNotificationWithIcon("success", "SuccessFully generated.");
      }
    } catch (error) {
      setGenerateState(generateState?.filter((row) => row !== rowIndex));

      openNotificationWithIcon("error", "Something wrong, Please try later.");
    }
  };

  /**
   * Download Pdf
   */
  const downloadPdf = async (type, rowIndex) => {
    setDownloadState([...downloadState, rowIndex]);
    openNotificationWithIcon("info", "Downloading PDF file.");

    const url = `/address/shipper/loads/${shipperId}/load/${shipperLoadId}/downloadFile/${type}`;
    try {
      const response = await getData(url, {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/pdf",
        },
      });

      const urls = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = urls;
      link.setAttribute("download", "file.pdf"); //or any other extension
      document.body.appendChild(link);
      link.click();

      if (response.status === 200) {
        setDownloadState(downloadState.filter((row) => row !== rowIndex));

        openNotificationWithIcon("success", "SuccessFully downloaded.");
      }
    } catch (error) {
      setDownloadState(downloadState.filter((row) => row !== rowIndex));

      openNotificationWithIcon("error", "Something wrong, Please try later.");
    }
  };

  const handleOpen = (button) => {
    setOpen(true);
    setSelectedButton(button);
  };

  return (
    <Box sx={{ m: 1, p: 1, backgroundColor: "#ffffff", width: "100%" }}>
      <Paper elevation={3} sx={{ m: 4, maxWidth: "90%" }}>
        <TableContainer component={Paper}>
          <Table sx={{ maxWidth: "100%" }} aria-label="customized table">
            <TableHead>
              <TableRow>
                {columns?.map((col) => {
                  return (
                    <TableCell key={`key-${col?.id}`}>{col.title}</TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {allList && Object.keys(allList)?.map((oneKey, i) => {
                return (
                  <StyledTableRow key={i}>
                    <StyledTableCell component="th" scope="row">
                      {oneKey}
                    </StyledTableCell>
                    <StyledTableCell>
                      {allList[oneKey]?.generatedOn
                        ? moment(allList[oneKey]?.generatedOn).format(
                            "YYYY-MM-DD HH:mm"
                          )
                        : "-"}
                    </StyledTableCell>

                    <StyledTableCell>
                      <Grid container spacing={1}>
                        <Grid item>
                          <Button
                            variant="outlined"
                            disabled={generateState?.includes(i)}
                            // onClick={() =>
                            //   generatePdf(allList[oneKey]?.fileType, i)
                            // }
                            onClick={() =>
                              handleOpen({
                                title: `Generate ${oneKey}`,
                                type: allList[oneKey]?.fileType,
                              })
                            }
                          >
                            {/* <EditIcon onClick={() => deleteEntry(row.title)} /> */}
                            {allList[oneKey]?.fileGenrated
                              ? "Regenerate File"
                              : "Generate File"}
                          </Button>
                        </Grid>
                        {allList[oneKey]?.fileGenrated ? (
                          <Grid item key={`key-${i}`}>
                            <Button
                              key={`key-${i}`}
                              variant="outlined"
                              disabled={downloadState?.includes(i)}
                              onClick={() =>
                                downloadPdf(allList[oneKey]?.fileType, i)
                              }
                            >
                              {/* <DeleteIcon onClick={() => deleteEntry(row.title)} /> */}
                              Download File
                            </Button>
                          </Grid>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Paper elevation={3} sx={{ m: 4, maxWidth: "90%" }}>
       {open && <PopupComponent
          shipperLoadData={shipperLoadData}
          shipperId={shipperId}
          shipperLoadId={shipperLoadId}
          open={open}
          setOpen={setOpen}
          selectedButton={selectedButton}
          setSelectedButton={setSelectedButton}
        />}
      </Paper>
    </Box>
  );
};

export default PdfGenerate;
