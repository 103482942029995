import React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useForm, Controller } from "react-hook-form";
import { postData } from "../../services/axiosInstance";
import { openNotificationWithIcon } from "../Layout/Toastify/toastify";
import { Grid, TextField } from "@mui/material";

function PopupComponent({
  shipperLoadData,
  shipperId,
  shipperLoadId,
  open,
  setOpen,
  selectedButton,
  setSelectedButton,
}) {
  //consigneeAddress

  //consignorAddress

  const { control, handleSubmit, reset, setValue, getValues } = useForm({
    defaultValues: {
      consignee: {},
      consignor: {},
    },
  });
  // const [open, setOpen] = React.useState(false);
  const [checkedVal, setCheckedVal] = React.useState({
    consignor: false,
    consignee: false,
    remit_to: '',
    for_ach:''
  });

  const handleClose = () => {
    reset();
    setOpen(false);
    setCheckedVal({
      consignor: false,
      consignee: false,
    });
    setSelectedButton("");
  };

  const onSubmit = async (data) => {
    const url = `/address/shipper/loads/${shipperId}/load/${shipperLoadId}/generateFile/${selectedButton?.type}`;
    const consigneeData = Object.keys(data.consignee).filter(
      (key) => data.consignee[key] === true
    );
    const consignorData = Object.keys(data.consignor).filter(
      (key) => data.consignor[key] === true
    );
    if (consigneeData.length > 0 && consignorData.length > 0) {
      // return;
      // Close the dialog and reset the form
      const payload = selectedButton?.type !== 'invoice' ? {
        consignee: consigneeData,
        consignor: consignorData,
      } : {
        consignee: consigneeData,
          consignor: consignorData, ...data
        }
      try {
        const response = await postData(url, payload);
        if (response.status === 200) {
          handleClose();
          reset();
          openNotificationWithIcon("success", "SuccessFully generated.");
        }
      } catch (error) {

        openNotificationWithIcon("error", "Something wrong, Please try later.");
      }
    } else {
      openNotificationWithIcon("error", "Please select consigor/consignee.");
      return;
    }
  };

  const handleSelectAll = (items, type, value) => {
    // setSelectedAll(value);
    const selectedItems = {};

    items.forEach((item) => {
      selectedItems[item._id] = value;
      setValue(`${type}[${item._id}]`, value);
    });

    // items.forEach((item) => setValue(`consignor[${item._id}]`, value));
    setCheckedVal((prev) => {
      return { ...prev, [type]: value };
    });
    setValue(type, selectedItems);
  };

  const handleChange = (value, name, type) => {
    setValue(name, value);

    let allValues = Object.values(getValues()[type]);
    setCheckedVal((prev) => {
      return {
        ...prev,
        [type]: allValues.every((val) => val),
      };
    });
  };

  return (
    <div>
      {/*  */}
      <Dialog fullWidth={true} open={open} onClose={handleClose}>
        <Box
          sx={{
            marginY: "10px",
            maxWidth: "100%",
          }}
        >
          <DialogTitle
            sx={{
              marginY: "10px",
            }}
          >
            Select Options To {selectedButton?.title}
          </DialogTitle>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
              <DialogContentText>
                Please select consignee/consigner options:
              </DialogContentText>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={6}
                  sx={{
                    maxWidth: "400px",
                  }}
                >
                  {/* Content for the first column */}
                  <div style={{ border: "1px solid #ccc", padding: "10px" }}>
                    <p>Select consignor</p>
                    {/* <CheckboxGroup /> */}
                    <List>
                      <ListItem>
                        <Checkbox
                          key="consignor"
                          edge="start"
                          onChange={(e) => {
                            handleSelectAll(
                              shipperLoadData?.consignorAddress,
                              "consignor",
                              e.target.checked
                            );
                          }}
                          checked={checkedVal?.consignor}
                        />
                        <ListItemText primary="Select All" />
                      </ListItem>
                      {shipperLoadData?.consignorAddress?.map((item) => {
                        return (
                          <ListItem key={item._id}>
                            <Controller
                              name={`consignor[${item._id}]`} // Use item ID as name
                              control={control}
                              // defaultValue={false}
                              // checked={consignor[item._id]}
                              render={({ field }) => {
                                return (
                                  <Checkbox
                                    edge="start"
                                    {...field}
                                    // checked={`consignor[${item._id}]`}
                                    checked={field?.value ? true : false}
                                    // value={field.value}
                                    onChange={(e) =>
                                      handleChange(
                                        e.target.checked,
                                        `consignor[${item._id}]`,
                                        "consignor"
                                      )
                                    }
                                  />
                                );
                              }}
                            />
                            <ListItemText primary={item.companyName} />
                          </ListItem>
                        );
                      })}
                    </List>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  {/* Content for the second column */}
                  <div style={{ border: "1px solid #ccc", padding: "10px" }}>
                    <p>Select consignee</p>
                    <List>
                      <ListItem>
                        <Checkbox
                          key="consignee"
                          edge="start"
                          onChange={(e) => {
                            handleSelectAll(
                              shipperLoadData?.consigneeAddress,
                              "consignee",
                              e.target.checked
                            );
                          }}
                          checked={checkedVal?.consignee}
                        />
                        <ListItemText primary="Select All" />
                      </ListItem>
                      {shipperLoadData?.consigneeAddress.map((item) => (
                        <ListItem key={item._id}>
                          <Controller
                            name={`consignee[${item._id}]`} // Use item ID as name
                            control={control}
                            // defaultValue={false}
                            render={({ field }) => {
                              return (
                                <Checkbox
                                  key={item._id}
                                  edge="start"
                                  {...field}
                                  checked={field?.value ? true : false}
                                  onChange={(e) =>
                                    handleChange(
                                      e.target.checked,
                                      `consignee[${item._id}]`,
                                      "consignee"
                                    )
                                  }
                                />
                              );
                            }}
                          />
                          <ListItemText primary={item.companyName} />
                        </ListItem>
                      ))}
                    </List>
                  </div>
                </Grid>
              </Grid>
              {selectedButton?.type === 'invoice'
                && <Grid container >
                <Grid
                  item
                  xs={6}
                  sx={{
                    maxWidth: "400px",
                  }}
                >
                  {/* Content for the first column */}
                  <div style={{  padding: "10px" }}>
                    <p>Remit to :</p>
                      <Controller
                        name="remit_to"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                            <TextField
                                {...field}
                                // label="Notes"
                                variant="outlined"
                                sx={{ width: "80%" }}
                            margin="normal"
                            rows={2}
                            maxRows={4}
                            multiline
                            />
                        )}
                        />
                  </div>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{
                    maxWidth: "400px",
                  }}
                >
                  {/* Content for the first column */}
                  <div style={{  padding: "10px" }}>
                    <p>For ACH :</p>
                      <Controller
                        name="for_ach"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                            <TextField
                                {...field}
                                // label="Notes"
                                variant="outlined"
                                sx={{ width: "80%" }}
                            margin="normal"
                            rows={2}
                            maxRows={4}
                            multiline
                            />
                        )}
                        />
                  </div>
                  </Grid>
               </Grid>}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                Create
              </Button>
            </DialogActions>
          </form>
        </Box>
      </Dialog>
    </div>
  );
}

export default PopupComponent;
