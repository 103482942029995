import {
  Backdrop,
  Box,
  Button,
  Fade,
  Grid,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { getData, postData } from "../../services/axiosInstance";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const schema = yup.object().shape({
  loadStatus: yup.string().required('Load status is required'),
  loadDescription: yup.string().required('Load description is required'),
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const UpdateLoadStatus = ({
  openAddModal,
  closeModalFunction,
  shipperId,
  shipperLoadId,
  addressType,
}) => {
  const { control, handleSubmit, reset, formState: {errors} } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      loadStatus: "",
      loadDescription: "",
    },
  });

  const modalsubmithandler = async (values) => {
    const payload = {
      ...values,
    };
    // return;
    const url = `/address/shipper/loads/${shipperId}/load/${shipperLoadId}/loadStatus`;
    try {
      const response = await postData(url, payload);
      if (response.status === 200) {
        reset();
        closeModalFunction({ refetch: true });
      }
    } catch (error) {
      // setDisabled(false);
    }
  };

  const statusKeys = {
    booked: "Booked",
    completed: "Completed",
    onroute: "On Route",
    payments: "Payments",
    invoicing: "Invoicing",
    delivered: "Delivered",
    collection: "Collection",
    disputed: "Disputed",
    cancelled: "Cancelled",
    watch: "Watch",
    clamied: "Clamied",
    tonu: "TONU",
    posted: "Posted",
    drafted: "Drafted",
    deleted: "Deleted",
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openAddModal}
      onClose={closeModalFunction}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={openAddModal}>
        <Box sx={style}>
          <Box
            component="form"
            onSubmit={handleSubmit(modalsubmithandler)}
            noValidate
            // onSubmit={handleSubmit}
            sx={{ mt: 1 }}
          >
            <div>
              <Typography variant="h6">Update Load Status</Typography>
            </div>
            <Controller
              name="loadStatus"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  id="outlined-select-currency"
                  label="Load Status"
                  variant="outlined"
                  placeholder="Load Status"
                  fullWidth
                  select
                >
                  {Object.keys(statusKeys)?.map((key) => (
                    <MenuItem key={key} value={key}>
                      {statusKeys[key]}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
            {errors.loadStatus && <Typography color="error" variant="body2">{errors.loadStatus.message}</Typography>}
            <Controller
              name="loadDescription"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  margin="normal"
                  id="outlined-required"
                  label="Description"
                  multiline
                  rows={4}
                  variant="outlined"
                  placeholder="Enter Description"
                  fullWidth
                />
              )}
            />
            {errors.loadDescription && <Typography color="error" variant="body2">{errors.loadDescription.message}</Typography>}

            <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
              Update Status
            </Button>
            <Grid
              container
              sx={{
                textAlign: "center",
              }}
            >
              <Grid item md={12}></Grid>
            </Grid>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default UpdateLoadStatus;
