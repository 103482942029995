import React, { useEffect} from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { Button, Checkbox, FormGroup, Grid } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import { Controller, useForm } from "react-hook-form";
import { getData, postData } from "../../services/axiosInstance";
import { openNotificationWithIcon } from "../Layout/Toastify/toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { contactDetailValidation } from "../../utils/shipperValidationSchema";

export default function ContactDetails({
  shipperId,
  ShipperData,
  fetchShipperData,
}) {
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(contactDetailValidation),
    defaultValues: {
      managerFirstName: "",
      managerLastName: "",
      managerEmail: "",
      managerPhone: "",
      ownerFirstName: "",
      ownerLastName: "",
      ownerEmail: "",
      ownerPhone: "",
      ownerStreetAddress: "",
      ownerCountry: "",
      ownerState: "",
      ownerCity: "",
      ownerZip: "",
    },
  });

  const countries = [
    {
      value: "US",
      label: "US",
    },
    {
      value: "Canada",
      label: "Canada",
    },
  ];

  const { managerFirstName, managerLastName, managerEmail, managerPhone } =
    watch();

  const sameAsShippingManagerCheck = (event) => {
    if (event.target.checked) {
      setValue("ownerFirstName", managerFirstName);
      setValue("ownerLastName", managerLastName);
      setValue("ownerEmail", managerEmail);
      setValue("ownerPhone", managerPhone);
    } else if (!event.target.checked) {
      setValue("ownerFirstName", "");
      setValue("ownerLastName", "");
      setValue("ownerEmail", "");
      setValue("ownerPhone", "");
    }
  };

  const { ownerZip, ownerCountry } = watch();

  useEffect(() => {
    async function fetchData() {
      if (ownerZip?.length > 4) {
        // get data from zip code
        const url = `/globalAreaDb/infoWithCountryZip?country_code=${ownerCountry}&zip=${ownerZip}`;
        try {
          const response = await getData(url);
          if (response.data.data === false) {
            openNotificationWithIcon("error", "Invalid zipcode.");
          }
          setValue("ownerState", response.data.data.admin_name1);
          setValue("ownerCity", response.data.data.place_name);
        } catch (error) {
          openNotificationWithIcon("error", "Invalid zipcode.");

        }
      }
    }
    fetchData();
    return () => {
      // perform any cleanup necessary
    };
  }, [ownerZip]);

  const sameAsCompanyAddress = (event) => {
    if (event.target.checked) {
      setValue("ownerStreetAddress", ShipperData?.streetAddress);
      setValue("ownerCountry", ShipperData?.country);
      setValue("ownerState", ShipperData?.state);
      setValue("ownerCity", ShipperData?.city);
      setValue("ownerZip", ShipperData?.zip);
    } else if (!event.target.checked) {
      setValue("ownerStreetAddress", "");
      setValue("ownerCountry", "");
      setValue("ownerState", "");
      setValue("ownerCity", "");
      setValue("ownerZip", "");
    }
  };
  const submitData = async (values) => {
    const payload = {
      managerDetails: {
        firstName: values.managerFirstName,
        lastName: values.managerLastName,
        email: values.managerEmail,
        phone: values.managerPhone,
      },
      ownerDetails: {
        firstName: values.ownerFirstName,
        lastName: values.ownerLastName,
        email: values.ownerEmail,
        phone: values.ownerPhone,
        streetAddress: values.ownerStreetAddress,
        country: values.ownerCountry,
        city: values.ownerCity,
        state: values.ownerState,
        zip: values.ownerZip,
      },
    };
    const url = `/address/shipper/contactDetails/${shipperId}`;
    try {
      const response = await postData(url, payload);
      if (response.status === 200) {
        openNotificationWithIcon("success", response?.data?.message);
      }
    } catch (error) {
      openNotificationWithIcon("error", error?.response?.data?.message);
    }
  };

  useEffect(() => {
    const getData = async () => {
      const response = await fetchShipperData();
      Object.keys(response).forEach((key) => {
        if (key === "managerDetails") {
          setValue("managerFirstName", response[key].firstName);
          setValue("managerLastName", response[key].lastName);
          setValue("managerEmail", response[key].email);
          setValue("managerPhone", response[key].phone);
        }
        if (key === "ownerDetails") {
          setValue("ownerFirstName", response[key].firstName);
          setValue("ownerLastName", response[key].lastName);
          setValue("ownerEmail", response[key].email);
          setValue("ownerPhone", response[key].phone);
          setValue("ownerStreetAddress", response[key].streetAddress);
          setValue("ownerCountry", response[key].country);
          setValue("ownerState", response[key].state);
          setValue("ownerCity", response[key].city);
          setValue("ownerZip", response[key].zip);
        }
      });
    };
    if (shipperId) {
      getData();
    }
  }, [shipperId]);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(submitData)}
      pl={1}
      pt={5}
      pb={5}
      sx={{
        // "& .MuiTextField-root": { m: 1, width: "25ch" },
        background: "#fff",
        width: "100%",
      }}
      noValidate
      autoComplete="off"
    >
      <Typography marginLeft={5} marginBottom={2}>
        Shipping Manager Details
      </Typography>
      <div>
        <Grid container spacing={1} ml={3}>
          <Grid item xs={2.5}>
            <Controller
              name="managerFirstName"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  
                  label="First Name"
                  variant="filled"
                  placeholder="Enter First Name"
                  autoComplete="off"
                />
              )}
            />
            <p style={{ color: "red" }}>{errors?.managerFirstName?.message}</p>
          </Grid>
          <Grid item xs={2.5}>
            <Controller
              name="managerLastName"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  
                  label="Last Name"
                  variant="filled"
                  placeholder="Enter Last Name"
                  autoComplete="off"
                />
              )}
            />
            <p style={{ color: "red" }}>{errors?.managerLastName?.message}</p>
          </Grid>
          <Grid item xs={2.5}>
            <Controller
              name="managerEmail"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  
                  id="outlined-required"
                  label="Email"
                  variant="filled"
                  placeholder="Enter Email"
                  autoComplete="off"
                />
              )}
            />
            <p style={{ color: "red" }}>{errors?.managerEmail?.message}</p>
          </Grid>
          <Grid item xs={2.5}>
            <Controller
              name="managerPhone"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  
                  id="outlined-required"
                  label="Phone"
                  variant="filled"
                  placeholder="Enter Phone"
                  autoComplete="off"
                />
              )}
            />
            <p style={{ color: "red" }}>{errors?.managerPhone?.message}</p>
          </Grid>
        </Grid>
      </div>
      <Typography marginLeft={5} marginBottom={2} marginTop={2}>
        Owner Details
      </Typography>
      <div
        style={{
          marginLeft: 40,
        }}
      >
       
        <FormGroup>
          <FormControlLabel
            control={<Checkbox />}
            label="Same as Shipping Manager"
            onChange={(e) => sameAsShippingManagerCheck(e)}
          />
        </FormGroup>
      </div>
      <div>
        <Grid container spacing={1} ml={3}>
          <Grid item xs={2.5}>
            <Controller
              name="ownerFirstName"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  
                  id="outlined-required"
                  label="First Name"
                  variant="filled"
                  placeholder="Enter First Name"
                  autoComplete="off"
                />
              )}
            />
            <p style={{ color: "red" }}>{errors?.ownerFirstName?.message}</p>
          </Grid>
          <Grid item xs={2.5}>
            <Controller
              name="ownerLastName"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  
                  id="outlined-required"
                  label="Last Name"
                  variant="filled"
                  placeholder="Enter Last Name"
                  autoComplete="off"
                />
              )}
            />
            <p style={{ color: "red" }}>{errors?.ownerLastName?.message}</p>
          </Grid>
          <Grid item xs={2.5}>
            <Controller
              name="ownerEmail"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  
                  id="outlined-required"
                  label="Email"
                  variant="filled"
                  placeholder="Enter Email"
                  autoComplete="off"
                />
              )}
            />
            <p style={{ color: "red" }}>{errors?.ownerEmail?.message}</p>
          </Grid>
          <Grid item xs={2.5}>
            <Controller
              name="ownerPhone"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  
                  id="outlined-required"
                  label="Phone"
                  variant="filled"
                  placeholder="Enter Phone"
                  autoComplete="off"
                />
              )}
            />
            <p style={{ color: "red" }}>{errors?.ownerPhone?.message}</p>
          </Grid>
        </Grid>
      </div>
      <div
        style={{
          marginLeft: 40,
        }}
      >
        <FormGroup>
          <FormControlLabel
            control={<Checkbox />}
            label="Same as Company"
            onChange={(e) => sameAsCompanyAddress(e)}
          />
        </FormGroup>
      </div>
      <div>
        <Grid container spacing={1} ml={3}>
          <Grid item xs={2.5}>
            <Controller
              name="ownerCountry"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  fullWidth
                  id="outlined-select-currency"
                  select
                  label="Country"
                  variant="filled"
                >
                  {countries.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
            <p style={{ color: "red" }}>{errors?.ownerCountry?.message}</p>
          </Grid>
          <Grid item xs={2.5}>
            <Controller
              name="ownerZip"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  
                  disabled={ownerCountry ? false : true}
                  id="outlined-required"
                  label="Zip"
                  variant="filled"
                  placeholder="Enter Company Zip"
                />
              )}
            />
            <p style={{ color: "red" }}>{errors?.ownerZip?.message}</p>
          </Grid>
          <Grid item xs={2.5}></Grid>
          <Grid item xs={2.5}></Grid>
        </Grid>
      </div>
      <div>
        <Grid container spacing={1} ml={3} mb={3} mt={3}>
          <Grid item xs={2.5}>
            <Controller
              name="ownerStreetAddress"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  
                  disabled={ownerCountry ? false : true}
                  id="outlined-required"
                  label="Street Address"
                  variant="filled"
                  placeholder="Enter Company Street Address"
                />
              )}
            />
          </Grid>
          <Grid item xs={2.5}>
            <Controller
              name="ownerCity"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  
                  disabled
                  id="outlined-required"
                  label="City"
                  variant="filled"
                  placeholder="Enter Company City"
                />
              )}
            />
          </Grid>
          <Grid item xs={2.5}>
            <Controller
              name="ownerState"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  
                  disabled
                  id="outlined-required"
                  label="State"
                  variant="filled"
                  placeholder="Enter Company State"
                />
              )}
            />
          </Grid>
          <Grid item xs={2.5}></Grid>
        </Grid>
      </div>
      <Button
        type="submit"
        variant="contained"
        sx={{
          marginTop: "10px",
          marginLeft: "40px",
          width: "20%",
        }}
      >
        Update
        {/* {!shipperId ? 'Save' : 'Update'} */}
      </Button>
    </Box>
  );
}
