import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  CardMedia,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import React from "react";
import { useSelector } from "react-redux";
import AuthLayout from "../components/Auth_Layout/AuthLayout";
// import List from "@mui/material/List";
// import ListItem from "@mui/material/ListItem";
// import ListItemText from "@mui/material/ListItemText";
import moment from "moment";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const UserProfile = () => {
  const userData = useSelector((state) => state?.user?.data);

  const columns = [
    {
      id: 1,
      title: "Sr.no",
    },
    {
      id: 2,
      title: "User",
    },
    {
      id: 3,
      title: "Logged In At",
    },
    {
      id: 4,
      title: "Logout At",
    },
  ];

  return (
    <AuthLayout>
      <Box mt={9} ml={2} mr={2} sx={{ width: "100%" }}>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card>
                <CardMedia
                  component="img"
                  alt="green iguana"
                  height="140"
                  image="/drivetms.png"
                />
                <CardContent>
                  <Typography variant="h5" component="div">
                    Name : {userData.name}
                  </Typography>
                  <Typography variant="subtitle1" component="div">
                    Email : {userData.email}
                  </Typography>
                  <Typography variant="subtitle2" color="text.secondary">
                    User Roles : {userData.roles.join(",")}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Workspace: {userData?.workspaceId?.name}
                  </Typography>
                </CardContent>
              </Card>
              {userData?.sessionHistory && (
                <>
                  <Paper elevation={3}>
                    <TableContainer>
                      <Table
                        sx={{ minWidth: "50%" }}
                        aria-label="customized table"
                      >
                        <TableHead>
                          <TableRow>
                            {columns?.map((col) => {
                              return (
                                <TableCell key={`key-${col.id}`} align="center">
                                  {col.title}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {userData?.sessionHistory?.map((row, index) => (
                            <StyledTableRow key={row._id}>
                              <StyledTableCell
                                align="center"
                                component="th"
                                scope="row"
                              >
                                {index + 1}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {row?.userId}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {moment(row?.createdAt).format(
                                  "YYYY-MM-DD HH:mm"
                                )}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {moment(row?.logoutAt).format(
                                  "YYYY-MM-DD HH:mm"
                                )}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </>
              )}
            </Grid>
            {/* <Grid item xs={4}>
              <Typography variant="h4">Last Login History</Typography>
              <Card
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <List>
                  {userData?.sessionHistory.map((history, index) => (
                    <ListItem key={index}>
                      <ListItemText
                        primary={"History"}
                        secondary={`Last login: ${moment(
                          history.createdAt
                        ).format("YYYY-MM-DD HH:mm")}`}
                      />
                    </ListItem>
                  ))}
                </List>
              </Card>
            </Grid> */}
          </Grid>
        </Box>
      </Box>
    </AuthLayout>
  );
};

export default UserProfile;
