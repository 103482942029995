import React, { useEffect, useState } from "react";
import { Typography, Grid, Box, Breadcrumbs, Paper } from "@mui/material";
import AuthLayout from "../components/Auth_Layout/AuthLayout";
import { Link, useParams } from "react-router-dom";

import { getData } from "../services/axiosInstance";
import ShipperInformation from "../components/shipper/shipperInformation";
import ContactDetails from "../components/shipper/contactDetails";
import LocationInformation from "../components/shipper/LocationInformation";
import ManagerDetails from "../components/shipper/ManagerDetails";
import OwnerDetails from "../components/shipper/OwnerDetails";
import PaymentDetails from "../components/shipper/PaymentDetails";
import CommoditiesDetails from "../components/shipper/CommoditiesDetails";
import AdditionalInformation from "../components/shipper/AdditionalInformation";
import DescriptionDetails from "../components/shipper/DescriptionDetails";

const ShipperDetail = () => {
  const { shipperId } = useParams();
  const [shipperData, setShipperData] = useState(null);

  const fetchShipperData = async () => {
    if (shipperId) {
      // get data from zip code
      const url = `/address/shipper/view/${shipperId}`;
      try {
        const response = await getData(url);
        if (response.status === 200) {
          setShipperData(response.data.data);
          return response.data.data;
        }
      } catch (error) {
        return {};
        // setFormValues(defaultValues);
        // openNotificationWithIcon('error', 'No details.');
      }
    }
  };
  useEffect(() => {
    if (shipperId) {
      fetchShipperData();
    }
  }, [shipperId]);

  console.log({ shipperData });

  return (
    <AuthLayout>
      {/* main box start  */}
      <Box mt={9} ml={2} mr={2} sx={{ width: "100%" }}>
        {/* breadcrums area  */}
        <Breadcrumbs aria-label="breadcrumb" mt={4} mb={2}>
          <Link underline="hover" color="inherit" href="/shippers">
            Shippers
          </Link>
          <Typography color="text.primary">View</Typography>
        </Breadcrumbs>

        {/* main page information area  */}
        <Paper elevation={3}>
          <Box mt={4} ml={4} sx={{ width: "100%" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={5}>
                {/* ShipperInformation */}
                <ShipperInformation shipperData={shipperData} />
              </Grid>

              <Grid item xs={12} sm={6} md={5}>
                <ContactDetails shipperData={shipperData} />
              </Grid>

              <Grid item xs={12} sm={6} md={5}>
                <LocationInformation shipperData={shipperData} />
              </Grid>

              <Grid item xs={12} sm={6} md={5}>
                <ManagerDetails shipperData={shipperData} />
              </Grid>

              <Grid item xs={12} sm={6} md={5}>
                <OwnerDetails shipperData={shipperData} />
              </Grid>

              <Grid item xs={12} sm={6} md={5}>
                <PaymentDetails shipperData={shipperData} />
              </Grid>

              <Grid item xs={10}>
                <DescriptionDetails shipperData={shipperData} />
              </Grid>

              <Grid item xs={10}>
                <CommoditiesDetails shipperData={shipperData} />
              </Grid>

              <Grid item xs={10}>
                <AdditionalInformation shipperData={shipperData} />
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>
    </AuthLayout>
  );
};

export default ShipperDetail;
