import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  Backdrop,
  Box,
  Button,
  Fade,
  Grid,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { postData } from "../../services/axiosInstance";
import { openNotificationWithIcon } from "../Layout/Toastify/toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const validationSchema = 
Yup.object().shape({
  // DOTNumber: Yup.string()
  //   .required("This field is required")
  //   .matches(/^[^\s].*[^\s]$/, "Empty spaces are not allowed"),
  // Docket: Yup.string()
  //   .required("This field is required")
  //   .matches(/^\S+$/, "Empty spaces are not allowed"),
});
const DOTModal = ({
  openAddModal,
  closeModalFunction,
  shipperId,
  shipperLoadId,
}) => {
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    setError,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      DOTNumber: "",
      Docket: "",
    },
  });

  const modalsubmithandler = async (values) => {
    const payload = {
      ...values,
    };
    const url = `/address/carrier/add`;

    try {
      const response = await postData(url, payload);
      if (response.status === 200) {
        reset();
        openNotificationWithIcon("success", response?.data.message);
        closeModalFunction({ refetch: true });
      }
    } catch (error) {
      // setDisabled(false);
    }
  };

  useEffect(() => {
    return () => {
      console.log("reset");
      reset();
    };
  }, []);

  const closeModal = () => {
    reset();
    closeModalFunction({ refetch: false });
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openAddModal}
      onClose={closeModal}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={openAddModal}>
        <Box sx={style}>
          <Box
            component="form"
            onSubmit={handleSubmit(modalsubmithandler)}
            noValidate
            // onSubmit={handleSubmit}
            sx={{ mt: 1 }}
          >
            <div>
              <Typography variant="h6">Add Carrier</Typography>
            </div>

            <Controller
              name="DOTNumber"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  margin="normal"
                  id="outlined-required"
                  label="DOT Number"
                  variant="standard"
                  placeholder="Enter Dot number"
                />
              )}
            />
            <p style={{ color: "red" }}>{errors?.DOTNumber?.message}</p>

            <Controller
              name="Docket"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  margin="normal"
                  id="outlined-required"
                  label="MC Number"
                  variant="standard"
                  placeholder="Enter MC number"
                />
              )}
            />
            <p style={{ color: "red" }}>{errors?.Docket?.message}</p>

            <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
              Add
            </Button>
            <Grid
              container
              sx={{
                textAlign: "center",
              }}
            >
              <Grid item md={12}></Grid>
            </Grid>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default DOTModal;
