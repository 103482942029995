import * as React from "react";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";
import BusinessIcon from "@mui/icons-material/Business";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import AddIcon from "@mui/icons-material/Add";
import DensitySmallIcon from "@mui/icons-material/DensitySmall";
import WebStoriesIcon from "@mui/icons-material/WebStories";
import DirectionsBoatFilledIcon from "@mui/icons-material/DirectionsBoatFilled";
import { useSelector } from "react-redux";

export default function NestedList(props) {
  const [open, setOpen] = React.useState(true);

  const userData = useSelector((state) => state?.user?.data);
  const [activeTab, setActiveTab] = React.useState(
    localStorage.getItem("activeTab")
  );
  const [open1, setOpen1] = React.useState(false);

  React.useEffect(() => {
    if (!props.open) {
      setOpen(false);
      setOpen1(false);
    }
  }, [props]);

  const handleClick = (activeTab) => {
    setActiveTab(activeTab);
    localStorage.setItem("activeTab", activeTab);
    setOpen(!open);
    props.getData(open);
  };
  // const handleClick1 = (activeTab) => {
  //   setActiveTab(activeTab)
  //   setOpen1(!open1);
  //   props.getData(open);
  // };
  return (
    <>
      <List
        sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="">
            Workspace
          </ListSubheader>
        }
      >
        <ListItemButton >
          <ListItemIcon>
            <WorkspacesIcon />
          </ListItemIcon>
          <ListItemText primary={userData?.workspaceId?.name} />

        </ListItemButton>
      </List>

      <List
        sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            Menu
          </ListSubheader>
        }
      >

        <ListItemButton onClick={() => handleClick("address")}>
          <ListItemIcon>
            <BusinessIcon />
          </ListItemIcon>
          <ListItemText primary="Address" />
          {activeTab === "address" ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse
          in={activeTab === "address"}
          timeout="auto"
          unmountOnExit
          sx={{ paddingLeft: "20px" }}
        >
          <List component="div">
            <Link
              sx={{ textDecoration: "none", color: "#3b3838" }}
              primary="Add Shipper"
              to="/addshipper"
            >
              <ListItemButton>
                <ListItemIcon>
                  <AddIcon />
                </ListItemIcon>
                <ListItemText primary="Add Shipper" />
              </ListItemButton>
            </Link>
            <Link
              sx={{ textDecoration: "none", color: "#3b3838" }}
              primary="Shipper"
              to="/Shipper"
            >
              <ListItemButton>
                <ListItemIcon>
                  <DirectionsBoatFilledIcon />
                </ListItemIcon>
                <ListItemText primary="Shipper" />
              </ListItemButton>
            </Link>
            <Link
              sx={{ textDecoration: "none", color: "#3b3838" }}
              primary="All loads"
              to="/loads"
            >
              <ListItemButton>
                <ListItemIcon>
                  <WebStoriesIcon />
                </ListItemIcon>
                <ListItemText primary="Loads" />
              </ListItemButton>
            </Link>
          </List>
        </Collapse>
        <ListItemButton onClick={() => handleClick("carriers")}>
          <ListItemIcon>
            <LocalShippingIcon />
          </ListItemIcon>
          <ListItemText primary="Carriers" />
          {activeTab === "carriers" ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse
          in={activeTab === "carriers"}
          timeout="auto"
          unmountOnExit
          sx={{ paddingLeft: "20px" }}
        >
          <List component="div" disablePadding>
            <Link
              sx={{ textDecoration: "none", color: "#3b3838" }}
              primary="Users"
              to="/carriers"
            >
              <ListItemButton>
                <ListItemIcon>
                  <DensitySmallIcon />
                </ListItemIcon>
                <ListItemText primary="All Carriers" />
              </ListItemButton>
            </Link>

            <ListItemButton sx={{ pl: 9 }}></ListItemButton>
          </List>
        </Collapse>
        {userData?.roles?.includes("superadmin") || userData?.roles?.includes("admin") ?
          <>
            <ListItemButton onClick={() => handleClick("users")}>
              <ListItemIcon>
                <PeopleAltIcon />
              </ListItemIcon>
              <ListItemText primary="Users" />
              {activeTab === "users" ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse
              in={activeTab === "users"}
              timeout="auto"
              unmountOnExit
              sx={{ paddingLeft: "20px" }}
            >
              <List component="div" disablePadding>
                <Link
                  sx={{ textDecoration: "none", color: "#3b3838" }}
                  primary="Users"
                  to="/users"
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <GroupAddIcon />
                    </ListItemIcon>
                    <ListItemText primary="Users" />
                  </ListItemButton>
                </Link>


                {userData?.roles?.includes("superadmin") &&
                  <Link
                    sx={{ textDecoration: "none", color: "#3b3838" }}
                    primary="Workspaces"
                    to="/workspaces"
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        <WorkspacesIcon />
                      </ListItemIcon>
                      <ListItemText primary="Workspaces" />
                    </ListItemButton>
                  </Link>}

              </List>
            </Collapse>
          </> : ''}
      </List>
    </>
  );
}
