import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  TableRow,
  Paper,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { styled } from "@mui/material/styles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const ManagerDetails = ({ shipperData }) => {
  return (
    shipperData && (
      <Card>
        <CardContent>
          <Typography variant="h6" color="primary">
            {" "}
            Manager Details
          </Typography>

          <Box mt={2}>
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: "100%" }}
                size={"small"}
                aria-label="table"
              >
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell align="left">
                      <strong>First Name:</strong>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {shipperData?.managerDetails?.firstName}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left">
                      <strong>last Name:</strong>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {shipperData?.managerDetails?.lastName}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left">
                      <strong>Email:</strong>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {shipperData?.managerDetails?.email}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left">
                      <strong>Phone:</strong>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {shipperData?.managerDetails?.phone}
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </CardContent>
      </Card>
    )
  );
};

export default ManagerDetails;
