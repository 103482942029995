import React from "react";
import { Navigate, Outlet } from "react-router-dom";
// import { useSelector } from 'react-redux';

const PrivateRoute = ({ element, ...rest }) => {
  // const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const isAuthenticated = localStorage.getItem("token");
  return isAuthenticated ? (
    <Outlet />
  ) : (
    // <Route path='/company' element={element} />
    <Navigate to="/login" replace />
  );
};

export default PrivateRoute;
