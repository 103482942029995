import React, { useEffect, useState } from "react";
import { Typography, Grid, Box, Breadcrumbs, Paper, Button, Badge } from "@mui/material";
import AuthLayout from "../components/Auth_Layout/AuthLayout";
import { Link, useParams } from "react-router-dom";

import { getData, postData } from "../services/axiosInstance";
import CarrierInformation from "../components/Carrier/carrierInformation";

const CarrierInfo = () => {
  const { carrierId } = useParams();
  const [carrierData, setCarrierData] = useState({});
  const [carrierStatus, setCarrierStatus] = useState('');
  const [filteredObj, setFilteredObj] = useState([]);
  const [FMCSAObj, setFMCSAObj] = useState([]);
  const [BasicInfo, setBasicInfo] = useState([]);
  const [certificateInfo, setCertificateInfo] = useState(null)


  const fetchCarrierData = async () => {
    if (carrierId) {
      // get data from zip code
      const url = `/address/carrier/view/${carrierId}`;
      try {
        const response = await getData(url);
        if (response.status === 200) {
          let data = response?.data?.data;
          setCarrierData(data);
          setCarrierStatus(data.parseStatus)
          setFilteredObj(data.parseCertificateData);
          setBasicInfo(data.parseBasicInfo)
          setFMCSAObj(data.parseFMCSAObj)
          setCertificateInfo(data['allData']['AssureAdvantage'][0]['CarrierDetails']['CertData']['Certificate'][0]['BlobName'])
          return response.data.data;
        }
      } catch (error) {
        return {};
      }
    }
  };

  useEffect(() => {
    if (carrierId) {
      fetchCarrierData();
    }
  }, [carrierId]);

  const filteredObjectData = filteredObj || [];
  const FMCSA = FMCSAObj || [];
  const InsBaseInfo = BasicInfo || [];

  const downloadCertificateInfo = async (blobName) => {
    try {

      const response = await postData("/address/carrier/download/carrier/blob", {
        blobAddress: blobName,
        carrierId
      }, {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/pdf",
        },
      })
      const urls = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = urls
      link.target = "_blank";
      link.setAttribute("download", blobName); //or any other extension
      document.body.appendChild(link);
      link.click();
      // Remove the link from the document
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading the PDF:', error);
    }
  }


  return (
    <AuthLayout>
      {/* main box start  */}
      <Box mt={10} mb={10} ml={4} mr={4} sx={{ width: "100%" }}>
        {/* breadcrums area  */}
        <Breadcrumbs aria-label="breadcrumb" mt={4} mb={2}>
          <Link underline="hover" color="inherit" href="/shippers">
            Carriers
          </Link>
          <Typography color="text.primary">View</Typography>
        </Breadcrumbs>

        {/* main page information area  */}
        <Paper elevation={3}>
          <Box mt={4} mb={4} ml={2} mr={2} pb={2} >
            <Badge badgeContent={carrierStatus} color="success">
              <Typography mt={3} mb={3} variant="h6" color="primary"> Basic Information</Typography>
            </Badge>
            <Grid container spacing={2} mb={2} >
              {InsBaseInfo.map((obj) => {
                return (
                  <Grid item xs={12} sm={12} md={6}>
                    <CarrierInformation data={obj.data} title={obj.title} />
                  </Grid>
                )
              })}
            </Grid>

            <Typography mt={3} mb={3} variant="h6" color="primary">
              Carrier Insurance on File with MyCarrierPortal
            </Typography>
            {carrierStatus === 'ACTIVE' ?
              <>
                <Button variant="contained" mb={4} size="small" onClick={() => downloadCertificateInfo(certificateInfo)}>
                  View Certificate
                </Button>
              </>
              : null}
            <Grid container spacing={2} mb={2} >
              {filteredObjectData.map((obj) => {
                return (
                  <Grid item xs={12} sm={6} md={4}>
                    <CarrierInformation data={obj.data} title={obj.title} />
                  </Grid>
                )
              })}
            </Grid>
            <Typography mt={3} mb={3} variant="h6" color="primary"> Carrier Insurance on File with the FMCS</Typography>
            <Grid container spacing={2} mb={2} >
              {(FMCSA.FmcsaData || []).map((obj) => {
                return (
                  <Grid item xs={12} sm={4} md={4}>
                    <CarrierInformation data={obj.data} title={obj.title} />
                  </Grid>
                )
              })}
            </Grid>
            <Grid container spacing={2} mb={2} >
              {(FMCSA.FmcsaPolicies || []).map((obj) => {
                return (
                  <Grid item xs={12} sm={4} md={4}>
                    <CarrierInformation data={obj.data} title={obj.title} />
                  </Grid>
                )
              })}
            </Grid>
          </Box>
        </Paper>
      </Box>
    </AuthLayout>
  );
};

export default CarrierInfo;
