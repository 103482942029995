import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import CompanyDetails from "../../CompanyDetails/Companydetails";
import ContactDetails from "../../ContactDetails/ContactDetails";
import PaymentDetails from "../../PaymentDetails/PaymentDetails";
import Commodities from "../../Commodities/Commodities";

export default function TabsOption({
  shipperId,
  ShipperData,
  fetchShipperData,
}) {
  const [value, setValue] = React.useState("company");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ typography: "body1" }} mt={2}>
      <TabContext value={value}>
        <Box
          sx={{ borderBottom: 1, borderColor: "transparent", width: "100%" }}
        >
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab
              sx={{ color: "#acacac" }}
              label="Company Details"
              value="company"
            />
            <Tab
              sx={{ color: "#acacac" }}
              label="Contact Details"
              value="contact"
              disabled={shipperId ? false : true}

            />
            <Tab
              sx={{ color: "#acacac" }}
              label="Payment Details"
              value="payment"
              disabled={shipperId ? false : true}

            />
            <Tab
              sx={{ color: "#acacac" }}
              label="commodities"
              value="commodities"
              disabled={shipperId ? false : true}

            />
          </TabList>
        </Box>
        <TabPanel sx={{ paddingLeft: "0px" }} value="company">
          <CompanyDetails
            shipperId={shipperId}
            ShipperData={ShipperData}
            fetchShipperData={fetchShipperData}
          />
        </TabPanel>
        <TabPanel sx={{ paddingLeft: "0px" }} value="contact">
          <ContactDetails
            shipperId={shipperId}
            ShipperData={ShipperData}
            fetchShipperData={fetchShipperData}
          />
        </TabPanel>
        <TabPanel sx={{ paddingLeft: "0px", height: "527px" }} value="payment">
          <PaymentDetails
            shipperId={shipperId}
            ShipperData={ShipperData}
            fetchShipperData={fetchShipperData}
          />
        </TabPanel>
        <TabPanel sx={{ paddingLeft: "0px" }} value="commodities">
          <Commodities
            shipperId={shipperId}
            ShipperData={ShipperData}
            fetchShipperData={fetchShipperData}
          />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
