import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";

import { useForm, Controller } from "react-hook-form";
import {
  TextField,
  Button,
  Autocomplete,
  Box,
  Backdrop,
  styled,
  MenuItem,
  Drawer,
  Container,
  Typography,
  TextareaAutosize,
  Modal,
  Fade,
} from "@mui/material";
import { postData, getDataDummy, getData } from "../../services/axiosInstance";
import { openNotificationWithIcon } from "../Layout/Toastify/toastify";
import ShipperInputData from "../../data.json";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  height: "80%",
};

const LoadCarrier = ({ shipperId, shipperLoadId, fetchShipperLoadData }) => {
  const [openAddModal, setAddModal] = useState(false);
  const [options, setOptions] = useState([]);
  const [data, setData] = useState({});
  const [alreadydata, setAlreadyData] = useState({});
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const getNewData = async () => {
    const response = await fetchShipperLoadData();
    console.log({ response });
    setAlreadyData(response?.carrierInfo?.carrierInfo || {});
  };
  const getoptions = async (newVal) => {
    const url = `/address/carrier/search/${encodeURI(newVal)}`;
    try {
      const response = await getData(url);
      if (response.status === 200) {
        console.log(response.data.data);
        setOptions(response.data.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (shipperId && shipperLoadId) {
      getNewData();
      //getoptions();
    }
  }, [shipperId, shipperLoadId, options]);

  const closeModalFunction = () => {
    setAddModal(false);
    setOptions([]);
    setData({});
  };

  const attachCarrier = async (target) => {
    let filtered = options.filter((a) => a._id == target._id);
    if (filtered.length > 0) {
      setData(filtered[0]);
      // openNotificationWithIcon("success", response?.data?.message);
    }
  };
  const submitDatas = async (e) => {
    e.preventDefault();
    let id = data._id;
    const url = `/address/shipper/loads/${shipperId}/load/${shipperLoadId}/loadCarrier`;
    try {
      const response = await postData(url, { carrierId: id });
      if (response.status === 200) {
        getNewData();

        closeModalFunction();
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (inputValue?.length > 0) {
      getoptions(inputValue);
    }
  }, [inputValue]);
  return (
    <Box sx={{ m: 2, p: 2, backgroundColor: "#ffffff", width: "100%" }}>
      {Object.keys(alreadydata).length > 0 ? (
        <>
          <Card sx={{ maxWidth: 345 }}>
            <CardMedia
              sx={{ height: 140 }}
              image="https://images.unsplash.com/photo-1613690399151-65ea69478674?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8c2hpcHBpbmd8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=400&q=60"
              title="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {alreadydata?.allData?.LegalName}
              </Typography>
              <b>Dot Number: </b>
              <small>{alreadydata?.dotNumber}</small>
              <br />
              <b>Mc Number: </b>
              <small>{alreadydata?.mcNumber}</small>
              <br />
              <b>City: </b>
              <small>{alreadydata?.allData?.City}</small>
              <br />
              <b>Zipcode: </b>
              <small>{alreadydata?.allData?.Zipcode}</small>
              <br />
              <b>State: </b>
              <small>{alreadydata?.allData?.State}</small>
              <br />
              <b>Country: </b>
              <small>{alreadydata?.allData?.Country}</small>
              <br />
              <b>Email: </b>
              <small>{alreadydata?.allData?.Email}</small>
              <br />
              <b>Dispatchers: </b>
              <small>{alreadydata?.allData?.Dispatchers}</small>
              <br />
              <br />
              <Typography variant="body2" color="text.secondary">
                {alreadydata?.allData?.MailingAddress1}
              </Typography>
            </CardContent>
          </Card>
        </>
      ) : (
        ""
      )}
      <Box
        sx={{ m: 2, p: 2, backgroundColor: "#ffffff", width: "100%" }}
        component="form"
        onSubmit={submitDatas}
      >
        <Button
          type="button"
          onClick={() => setAddModal(true)}
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Attach Carrier
        </Button>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={openAddModal}
          onClose={closeModalFunction}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={openAddModal}>
            <Box sx={style} component="form" onSubmit={(e) => submitDatas(e)}>
              <Box noValidate sx={{ mt: 1 }}>
                <Typography variant="h5" sx={{ mt: 1, mb: 2 }}>
                  Select Carrier
                </Typography>
                <Autocomplete
                  id="grouped-demo"
                  name="search"
                  autoHighlight
                  options={options.sort(
                    (a, b) =>
                      -b.allData.LegalName.localeCompare(a.allData.LegalName)
                  )}
                  freeSolo
                  getOptionLabel={(option) => option.allData.LegalName}
                  sx={{ width: "100%" }}
                  filterOptions={(x) => x}
                  autoComplete
                  includeInputInList
                  filterSelectedOptions
                  value={value}
                  noOptionsText="No locations"
                  onChange={(event, newValue) => {
                    attachCarrier(newValue);
                  }}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  renderInput={(params) => (
                    <div ref={params.InputProps.ref}>
                      <TextField
                        {...params}
                        name="search"
                        label="Search Carriers"
                      />
                    </div>
                  )}
                />
              </Box>
              <Box sx={{ width: "100%" }}>
                <div>
                  {Object.keys(data).length > 0 ? (
                    <Box
                      sx={{
                        m: 2,
                        p: 2,
                        backgroundColor: "#ffffff",
                        width: "100%",
                      }}
                    >
                      <Card sx={{ maxWidth: "100%" }}>
                        <CardContent>
                          <Typography gutterBottom variant="h5" component="div">
                            {data?.allData?.LegalName}
                          </Typography>
                          <br />
                          <b>Dot Number: </b>
                          <small>{data?.dotNumber}</small>
                          <br />
                          <b>Mc Number: </b>
                          <small>{data?.mcNumber}</small>
                          <br />
                          <b>City: </b>
                          <small>{data?.allData?.City}</small>
                          <br />
                          <b>Zipcode: </b>
                          <small>{data?.allData?.Zipcode}</small>
                          <br />
                          <b>State: </b>
                          <small>{data?.allData?.State}</small>
                          <br />
                          <b>Country: </b>
                          <small>{data?.allData?.Country}</small>
                          <br />
                          <b>Email: </b>
                          <small>{data?.allData?.Email}</small>
                          <br />
                          <b>MailingAddress1: </b>
                          <small>{data?.allData?.MailingAddress1}</small>
                          <br />
                          <b>Dispatchers: </b>
                          <small>{data?.allData?.Dispatchers}</small>
                          <br />
                          <br />

                          <Button
                            sx={{
                              marginTop: 2,
                              marginLeft: 2,
                              marginBottom: 2,
                              width: "50%",
                            }}
                            type="submit"
                            // onClick={() => toggleDrawer()}
                            variant="contained"
                          >
                            Attach Carrier
                          </Button>
                        </CardContent>
                      </Card>
                    </Box>
                  ) : (
                    ""
                  )}
                </div>
              </Box>
            </Box>
          </Fade>
        </Modal>
      </Box>
    </Box>
  );
};

export default LoadCarrier;
