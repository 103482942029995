import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Typography, TextField, Button, Box } from "@mui/material";

const Commodity = ({
  edit,
  editIndex,
  allListData,
  // onDataUpdate,
  addCommodity,
  editRow,
  updateCommodity,
}) => {
  const { handleSubmit, control, reset, setValue } = useForm({
    defaultValues: {
      commodity: "",
      units: "",
      weight: "",
      value: "",
      poNumber: "",
      pickupDate: "",
      pickupTimeFrom: "",
      pickupTimeTo: "",
      loadDescription: "",
    },
  });

  useEffect(() => {
    if (editRow) {
      Object.keys(editRow)?.map((key) => {
        setValue(key, editRow[key]);
      });
    }
  }, []);

  const submitData = (e) => {
    const newObject = {
      commodity: e.commodity,
      units: e.units,
      weight: e.weight,
      value: e.value,
      poNumber: e.poNumber,
      pickupDate: e.pickupDate,
      pickupTimeFrom: e.pickupTimeFrom,
      pickupTimeTo: e.pickupTimeTo,
      loadDescription: e.loadDescription,
    };
    addCommodity(newObject);
  };

  const updateData = (e) => {
    console.log({ e });
    updateCommodity(e);
    // const updatedList = [...allListData];
    // updatedList[editIndex] = {
    //   ...updatedList[editIndex],
    //   commodity: e.commodity,
    //   units: e.units,
    //   weight: e.weight,
    //   value: e.value,
    //   poNumber: e.poNumber,
    //   pickupDate: e.pickupDate,
    //   pickupTimeFrom: e.pickupTimeFrom,
    //   pickupTimeTo: e.pickupTimeTo,
    //   loadDescription: e.loadDescription,
    // };
    // onDataUpdate(updatedList);
    // setEdit(false);
    // setEditIndex(0);
    // setDrawer(false);
  };

  const currentDate = new Date().toISOString().split("T")[0];

  return (
    <>
      <Box p={2} mt={10} sx={{ width: "100%" }}>
        <Typography variant="h6">Commodity Details</Typography>
        <form
          onSubmit={
            editRow ? handleSubmit(updateData) : handleSubmit(submitData)
          }
          noValidate
        >
          <Box mt={2}>
            <Controller
              name="commodity"
              control={control}
              defaultValue={editRow ? editRow?.commodity : ""}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Commodity"
                  variant="outlined"
                  fullWidth
                  required
                />
              )}
            />
          </Box>
          <Box mt={2}>
            <Controller
              name="units"
              control={control}
              defaultValue={editRow ? editRow?.units : ""}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Units"
                  variant="outlined"
                  fullWidth
                  required
                />
              )}
            />
          </Box>
          <Box mt={2}>
            <Controller
              name="weight"
              control={control}
              defaultValue={editRow ? editRow?.weight : ""}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Weight"
                  variant="outlined"
                  fullWidth
                  required
                />
              )}
            />
          </Box>
          <Box mt={2}>
            <Controller
              name="value"
              control={control}
              defaultValue={editRow ? editRow?.value : ""}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Value"
                  variant="outlined"
                  fullWidth
                  required
                />
              )}
            />
          </Box>
          <Box mt={2}>
            <Controller
              name="poNumber"
              control={control}
              defaultValue={editRow ? editRow?.poNumber : ""}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="PO Number"
                  variant="outlined"
                  fullWidth
                  required
                />
              )}
            />
          </Box>

          <Box mt={4}>
            <Typography variant="h6">PickUp Details</Typography>
            <Box mt={2}>
              <Controller
                name="pickupDate"
                control={control}
                sx={{
                  width: "10%",
                }}
                defaultValue={editRow ? editRow?.pickupDate : currentDate}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="date"
                    label=""
                    variant="outlined"
                    placeholder="Enter date"
                    required
                  />
                )}
              />
              &nbsp;&nbsp;
              <Controller
                name="pickupTimeFrom"
                control={control}
                defaultValue={editRow ? editRow?.pickupTimeFrom : ""}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="time"
                    label=""
                    variant="outlined"
                    required
                  />
                )}
              />
              &nbsp;&nbsp;
              <Controller
                name="pickupTimeTo"
                control={control}
                defaultValue={editRow ? editRow?.pickupTimeTo : ""}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="time"
                    label=""
                    variant="outlined"
                    required
                  />
                )}
              />
            </Box>
          </Box>

          <Box mt={4}>
            <Typography variant="h6">Load Description</Typography>
            <Box mt={2}>
              <Controller
                name="loadDescription"
                control={control}
                defaultValue={editRow ? editRow?.loadDescription : ""}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    multiline
                    rows={4}
                    label="Load Description"
                    placeholder="Enter Description"
                    variant="outlined"
                    fullWidth
                    required
                  />
                )}
              />
            </Box>
          </Box>

          <Box mt={4}>
            <Button variant="contained" type="submit">
              {editRow ? "Update" : "Save"}
            </Button>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default Commodity;
