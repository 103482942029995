import React from 'react'

import { Card, CardContent, Typography, Box, Paper, TableRow } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { styled } from "@mui/material/styles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));
const ShipperInformation = ({ shipperData }) => {

    return shipperData && (
        <Card>
            <CardContent>
                <Typography variant="h6" color="primary">Shipper Information</Typography>
                <Box mt={2}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: "100%" }} size={'small'} aria-label="table">
                            <TableBody>
                                <StyledTableRow >
                                    <StyledTableCell align="left">
                                        <strong>Ship Tag:</strong>
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {shipperData?.shipTag}
                                    </StyledTableCell>
                                </StyledTableRow >
                                <StyledTableRow >
                                    <StyledTableCell align="left">
                                        <strong>Shipper Status:</strong>
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {shipperData?.shipperStatus}
                                    </StyledTableCell>
                                </StyledTableRow >
                                <StyledTableRow >
                                    <StyledTableCell align="left">
                                        <strong>Company Name:</strong>
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {shipperData?.companyName}
                                    </StyledTableCell>
                                </StyledTableRow >
                                <StyledTableRow >
                                    <StyledTableCell align="left">
                                        <strong>Doing Business:</strong>
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {shipperData?.doingBusiness}
                                    </StyledTableCell>
                                </StyledTableRow >
                                <StyledTableRow >
                                    <StyledTableCell align="left">
                                        <strong>Reference Name:</strong>
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {shipperData?.refName}
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow >

                                    <StyledTableCell align="left">
                                        <strong>Industry Type:</strong>
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {shipperData?.industryType}
                                    </StyledTableCell>

                                </StyledTableRow>

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </CardContent>
        </Card>
    )
}

export default ShipperInformation