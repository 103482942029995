/* eslint-disable no-unused-expressions */
import React, { useState } from "react";
import Logo from "../assets/images/logo.png";
import LoginImage from "../assets/images/login-image.jpg";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { LOGIN_VALIDATION_SCHEMA } from "../utils/loginSchema";
import { postData } from "../services/axiosInstance";
import { useNavigate } from "react-router-dom";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { useDispatch } from "react-redux";

import { openNotificationWithIcon } from "../components/Layout/Toastify/toastify";
import { fetchUser } from "../store/userSlice";

const Login = () => {
  localStorage.clear()
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const [show, setShow] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    resolver: yupResolver(LOGIN_VALIDATION_SCHEMA),
    defaultValues: {
      email: "",
      password: "",
    },
  });
  const submitData = async (values) => {
    setDisabled(true);
    const payload = { ...values };
    const url = "/auth/login";

    try {
      const response = await postData(url, payload, {
        headers: {
          "Content-Type": "application/json",
          Accept: "/",
        },
      });

      if (response.status === 200) {
        openNotificationWithIcon("success", response?.data?.message);
        const { access, name } = response.data.data;
        localStorage.setItem("token", access);
        localStorage.setItem("username", name);

        dispatch(fetchUser());

        navigate("/");
      }
    } catch (error) {
      setDisabled(false);
      openNotificationWithIcon("error", error?.response?.data?.message);
    }
  };
  return (
    <div className="w-full min-h-screen bg-cyan-50 flex rounded-sm border border-stroke bg-cyan-50 shadow-default dark:border-strokedark dark:bg-boxdark">
      <div className="hidden lg:flex flex-col flex-wrap w-1/2 bg-cyan-100">
        <div className="p-4">
          <img src={Logo} alt="logo" />
        </div>
        <div className="w-full p-8">
          <img src={LoginImage} className="w-3/4" alt="login-image" />
        </div>
      </div>
      <div className="w-full lg:w-1/2 border-stroke dark:border-strokedark xl:w-1/2 xl:border-l-2 flex flex-col justify-center p-8">
        <div className="w-full p-4">
          <h2 className="mb-9 text-2xl font-bold text-black dark:text-white sm:text-title-xl2">
            Sign In
          </h2>
          <form
            className="flex flex-col gap-6"
            onSubmit={handleSubmit(submitData)}
          >
            <div className="relative w-full ">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Email
              </label>
              <input
                type="email"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder=" Enter Email"
                {...register("email")}
              />
              {errors.email ? (
                <span className="text-rose-500 text-left text-sm">
                  {errors?.email?.message}
                </span>
              ) : (
                <span className="h-4"></span>
              )}
            </div>
            <div className="relative w-full ">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Password
              </label>
              <input
                type={`${show ? "text" : "password"}`}
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Enter Password"
                {...register("password")}
              />
              {errors.password ? (
                <span className="text-rose-500 text-left text-sm">
                  {errors?.password?.message}
                </span>
              ) : (
                <span className="h-4"></span>
              )}
            </div>
            <div>
              <label className="inline-flex items-center cursor-pointer">
                <input
                  onClick={() => setShow(!show)}
                  id="customCheckLogin"
                  type="checkbox"
                  className="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                />
                <span className="ml-2 text-sm font-semibold text-blueGray-600">
                  Show password
                </span>
              </label>
            </div>

            <div className="text-center mt-6">
              <button
                className="bg-cyan-700 text-white text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                type="submit"
                label="Sign In"
                disabled={disabled}
              >
                Sign In
              </button>
            </div>
          </form>
        </div>
      </div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          This is a success message!
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Login;
