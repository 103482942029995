import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  TableRow,
  Paper,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { styled } from "@mui/material/styles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const PaymentDetails = ({ shipperData }) => {
  return (
    shipperData && (
      <Card>
        <CardContent>
          <Typography variant="h6" color="primary">
            {" "}
            Payment Details
          </Typography>
          <Box mt={2}>
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: "100%" }}
                size={"small"}
                aria-label="table"
              >
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell align="left">
                      <strong>Prepaid:</strong>{" "}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {shipperData?.paymentDetails?.isPrepaid ? "Yes" : "No"}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left">
                      <strong>Payment Terms:</strong>{" "}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {shipperData?.paymentDetails?.paymentTerms}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left">
                      <strong>Payment Method:</strong>{" "}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {shipperData?.paymentDetails?.paymentMethod}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left">
                      <strong>Method of Invoice:</strong>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <Typography>
                        <Box ml={2}>
                          <StyledTableCell align="left">
                            <strong>Email:</strong>
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {shipperData?.paymentDetails?.methodOfInvoice.email
                              ? "Yes"
                              : "No"}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <strong>Paper:</strong>
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {shipperData?.paymentDetails?.methodOfInvoice.email
                              ? "Yes"
                              : "No"}
                          </StyledTableCell>
                        </Box>
                      </Typography>
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left">
                      <strong>Credit Limit Requested:</strong>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {shipperData?.paymentDetails?.creditLimitRequested}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left">
                      <strong>Prefer Documents:</strong>
                    </StyledTableCell>
                    <StyledTableCell align="left"></StyledTableCell>
                  </StyledTableRow>

                  {shipperData?.paymentDetails?.preferDocument.map((doc) => {
                    return (
                      <StyledTableRow>
                        <StyledTableCell align="left">
                          <strong>{doc?.title}</strong>
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {doc?.required ? "Yes" : "No"}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </CardContent>
      </Card>
    )
  );
};

export default PaymentDetails;
