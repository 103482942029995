import * as Yup from "yup";

const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const USER_ADD_VALIDATION_SCHEMA = Yup.object().shape({
    email: Yup.string()
    .required("Email is required")
    .trim()
    .lowercase("Email must be in lowercase")
    .matches(emailRegex, "Invalid email format"),
    password: Yup.string().required("Password is required"),
    name: Yup.string().required("Name is required"),
    role: Yup.array()
    .of(Yup.string().required("Each role is required"))
    .required("Role selection is required")
    .min(1, "At least one role must be selected")
});

const USER_UPDATE_VALIDATION_SCHEMA = Yup.object().shape({
    email: Yup.string()
    .required("Email is required")
    .trim()
    .lowercase("Email must be in lowercase")
    .matches(emailRegex, "Invalid email format"),
    name: Yup.string().required("Name is required"),
    role: Yup.array()
    .of(Yup.string().required("Each role is required"))
    .required("Role selection is required")
    .min(1, "At least one role must be selected")
});

export { USER_ADD_VALIDATION_SCHEMA, USER_UPDATE_VALIDATION_SCHEMA };