import React from 'react'
import { Card, CardContent, Typography, Box, TableRow, Paper } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { styled } from "@mui/material/styles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const LocationInformation = ({ shipperData }) => {
    return shipperData && (
        <Card>
            <CardContent>
                <Typography variant="h6" color="primary"> Location Information</Typography>
                <Box mt={2}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: "100%" }} size={'small'} aria-label="table">
                            <TableBody>
                                <StyledTableRow >
                                    <StyledTableCell align="left">
                                        <strong>Street Address:</strong>
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {shipperData?.streetAddress}
                                    </StyledTableCell>
                                </StyledTableRow >
                                <StyledTableRow >
                                    <StyledTableCell align="left">
                                        <strong>Country:</strong>
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {shipperData?.country}
                                    </StyledTableCell>
                                </StyledTableRow >
                                <StyledTableRow >
                                    <StyledTableCell align="left">
                                        <strong>City:</strong>
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {shipperData?.city}
                                    </StyledTableCell>
                                </StyledTableRow >
                                <StyledTableRow >
                                    <StyledTableCell align="left">
                                        <strong>State:</strong>
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {shipperData?.state}
                                    </StyledTableCell>
                                </StyledTableRow >
                                <StyledTableRow >
                                    <StyledTableCell align="left">
                                        <strong>ZIP Code:</strong>
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {shipperData?.zip}
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow >

                                    <StyledTableCell align="left">
                                        <strong>Type of Business:</strong>

                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {shipperData?.typeOfBusiness}
                                    </StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow >

                                    <StyledTableCell align="left">
                                        <strong>Years in Business:</strong>


                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {shipperData?.yearsInBusiness}
                                    </StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow >

                                    <StyledTableCell align="left">
                                        <strong>Location:</strong>

                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {shipperData?.location}
                                    </StyledTableCell>

                                </StyledTableRow>


                                <StyledTableRow >

                                    <StyledTableCell align="left">
                                        <strong>Federal Tax ID:</strong>

                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {shipperData?.fedTaxId}
                                    </StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow >

                                    <StyledTableCell align="left">
                                        <strong>Incorporation State:</strong>

                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {shipperData?.incorporationState}
                                    </StyledTableCell>
                                </StyledTableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </CardContent>
        </Card>
    )
}

export default LocationInformation