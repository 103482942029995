import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import LoadPageTabs from "../../components/Layout/LoadPageTabs/LoadPageTabs";
import { useParams } from "react-router-dom";
import { getData } from "../../services/axiosInstance";

import AuthLayout from "../../components/Auth_Layout/AuthLayout";

export default function Load() {
  const { shipperId, shipperLoadId } = useParams();
  const [shipperData, setShipperData] = useState(null);
  const [shipperLoadData, setShipperLoadData] = useState({});

  const fetchCompanyData = async () => {
    if (shipperId) {
      // get data from zip code
      const url = `/address/shipper/view/${shipperId}`;
      try {
        const response = await getData(url);
        if (response.status === 200) {
          // setFormValues(response.data.data);
          setShipperData(response.data.data);
          return response.data.data;
        }
      } catch (error) {
        return {};
        // setFormValues(defaultValues);
        // openNotificationWithIcon('error', 'No details.');
      }
    }
  };
  const fetchShipperLoadData = async () => {
    if (shipperId && shipperLoadId) {
      const url = `/address/shipper/loads/${shipperId}/view/${shipperLoadId}`;
      try {
        const response = await getData(url);
        if (response.status === 200) {
          // setFormValues(response.data.data);
          setShipperLoadData(response.data.data);
          return response.data.data;
        }
      } catch (error) {
        return {};
        // setFormValues(defaultValues);
        // openNotificationWithIcon('error', 'No details.');
      }
    }
  };

  useEffect(() => {
    if (shipperId) {
      fetchCompanyData();
    }
  }, [shipperId]);

  return (
    <AuthLayout>
      <Box
        sx={{
          display: "flex",
          width: "100%",
        }}
      >
        <Box mt={12} ml={2} sx={{ width: "100%" }}>
          <Typography component="div">
            <Box sx={{ m: 1, textTransform: "capitalize", fontWeight: "bold" }}>
              Load : {shipperLoadData?.loadTag}, {shipperData?.shipTag} [
              {shipperData?.companyName}]
            </Box>
            <Box sx={{ m: 1, textTransform: "capitalize" }}>
              {shipperLoadData?.loadTag ? (
                <b>Status : {shipperLoadData?.currentStatus}</b>
              ) : null}
            </Box>
          </Typography>

          <LoadPageTabs
            loadId={shipperLoadId}
            fetchLoadData={fetchShipperLoadData}
            shipperId={shipperId}
            ShipperData={shipperData}
            shipperLoadData={shipperLoadData}
          />
        </Box>
      </Box>
    </AuthLayout>
  );
}
