import React from 'react'
import { Card, CardContent, Typography, Box, TableRow, Paper } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { styled } from "@mui/material/styles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const AdditionalInformation = ({ shipperData }) => {
    return shipperData && (
        <Card>
            <CardContent>
                <Typography variant="h6" color="primary"> Additional Information</Typography>
                <Box mt={2}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: "100%" }} size={'small'} aria-label="table">
                            <TableBody>
                                <StyledTableRow >
                                    <StyledTableCell align="left">
                                        <strong>Added By:</strong>
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {shipperData?.addedBy}
                                    </StyledTableCell>
                                </StyledTableRow >
                                <StyledTableRow >
                                    <StyledTableCell align="left">
                                        <strong>Last Updated By:</strong>
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {shipperData?.lastUpdatedBy}
                                    </StyledTableCell>
                                </StyledTableRow >
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </CardContent>
        </Card>
    )
}

export default AdditionalInformation