import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {
  Breadcrumbs,
  Button,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { postData } from "../services/axiosInstance";
import { Link, useNavigate } from "react-router-dom";
import AuthLayout from "../components/Auth_Layout/AuthLayout";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";
export default function AddWorkspace() {
  // const [listData, setUserList] = useState([]);
  const navigate = useNavigate();
  const userData = useSelector((state) => state?.user?.data);
  const { control, handleSubmit } = useForm({
    // resolver: yupResolver(schema),
    defaultValues: {
      name: "",
    },
  });

  useEffect(() => {
    if (
      !(
        userData.roles.includes("superadmin") ||
        userData.roles.includes("admin")
      )
    ) {
      navigate("/");
    }
  }, []);
  const submitData = async (values) => {
    let payload = {};
    const url = "/workspaces/add";
    payload = {
      name: values.name,
    };
    try {
      const response = await postData(url, payload);
      if (response.status === 200) {
        // openNotificationWithIcon('success', response?.data?.message);
        setTimeout(() => {
          navigate("/workspaces");
        }, 2000);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  return (
    <AuthLayout>
      {/* main box start  */}
      <Box mt={9} ml={2} mr={2} sx={{ width: "100%" }}>
        {/* breadcrums area  */}
        <Breadcrumbs aria-label="breadcrumb" mt={4} mb={2}>
          <Link underline="hover" color="inherit" href="/workspaces">
            Workspaces
          </Link>
          <Typography color="text.primary">Add</Typography>
        </Breadcrumbs>

        {/* main page information area  */}
        <Paper elevation={3}>
          <Box
            component="form"
            pt={5}
            pb={5}
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
              background: "#fff",
              width: "100%",
            }}
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(submitData)}
          >
            <div>
              <Grid container spacing={1} ml={3}>
                <Grid item xs={2.5}>
                  <Controller
                    name="name"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        required
                        id="outlined-required"
                        label="Workspace name"
                        variant="outlined"
                        placeholder="Enter Workspace Name"
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Button
                variant="contained"
                sx={{
                  marginTop: "5px",
                  marginLeft: "40px",
                  width: "15%",
                }}
                type="submit"
              >
                Save
              </Button>
            </div>
          </Box>
        </Paper>
      </Box>
    </AuthLayout>
  );
}
