import { Box } from "@mui/system";
import React from "react";
import Sidebar from "./Sidebar/sidebar";

const AuthLayout = ({ children }) => {
  return (
    <div>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Sidebar />
        {children}
      </Box>
    </div>
  );
};

export default AuthLayout;
