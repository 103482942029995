import React from 'react'

import { Card, CardContent, TableRow} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";


const CarrierInformation = ({ data, title }) => {

    return data && (
        <Card>
            <CardContent>

                <h3 fontSize={'1rem'}  style={{ marginLeft: 10, marginBottom: 10 }}>
                    <strong>{title}</strong>
                </h3>

                <TableContainer component={''}>
                    <Table className={'infoTable'} sx={{ minWidth: "100%" }} size={'small'}>
                        <TableBody>
                            {Object.keys(data || [])?.map((key, index) => {
                                return (
                                    <TableRow key={key + "-" + index}>
                                        <TableCell align="left">
                                            <strong  style={{ color: '' }} >{key}</strong> : <span color="inherit" >{`${data[key]}`}</span>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>

            </CardContent>
        </Card>
    )
}

export default CarrierInformation