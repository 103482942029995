
import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import {
    TextField,
    Button,

    Box,
    Grid,
    Card,
} from "@mui/material";
import { postData } from "../../services/axiosInstance";
import { openNotificationWithIcon } from "../Layout/Toastify/toastify";



const Instructions = ({ shipperId, shipperLoadId, fetchShipperLoadData }) => {
    const { handleSubmit, control, setValue } = useForm({
        defaultValues: {
            standingInstructions: "",
            shipperCarrierInstructions: "",
        },
    });

    useEffect(() => {
        const getData = async () => {
            const response = await fetchShipperLoadData();
            Object.keys(response).forEach((key) => {
                setValue("standingInstructions", response["standingInstructions"]);
                setValue("shipperCarrierInstructions", response["shipperCarrierInstructions"]);
            });
        };
        if (shipperId && shipperLoadId) {
            getData();
        }
    }, [shipperId, shipperLoadId]);

    const onSubmit = async (values) => {
        const payload = { ...values }
        const url = `/address/shipper/loads/${shipperId}/load/${shipperLoadId}/instruction`
        try {
            const response = await postData(url, payload);
            if (response.status === 200) {
                openNotificationWithIcon('success', response?.data?.message);
            }
        } catch (error) {
            openNotificationWithIcon('error', error?.response?.data?.message);
        }
    };

    return (
            <Card
            variant="outlined"
            sx={{
              marginLeft: 5,
              // width: "90%",
              // marginBottom: 5,
              padding: "10px 0px",
              background: "aliceblue",
            }}
          >
        <Box sx={{ m: 2, p: 2,  width: "100%" }}>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <Grid item xs={2.5}>
                    <Controller
                        name="standingInstructions"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Standing Instruction"
                                variant="outlined"
                                sx={{ width: "80%" }}
                                margin="normal"
                            />
                        )}
                        />
                    </Grid>
                <Grid>
                    Shipper and Carrier Instructions
                </Grid>
                <Grid item xs={2.5}>

                    <Controller
                        name="shipperCarrierInstructions"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                            <TextField
                                {...field}
                                multiline
                                sx={{ width: "80%" }}
                                rows={2}
                                maxRows={4}
                                // label="Description"
                                variant="outlined"

                                margin="normal"
                            />
                        )}
                    />
                </Grid>



                <Button type="submit" variant="contained" color="primary" mt={4}>
                    Save
                </Button>
            </form>


        </Box>
        </Card>
    );
};

export default Instructions;
