import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  TextField,
  Button,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  styled,
  Grid,
  MenuItem,
  Drawer,
  Container,
  Typography,
  TextareaAutosize,
  Paper,
} from "@mui/material";
import { postData } from "../../services/axiosInstance";
import { openNotificationWithIcon } from "../Layout/Toastify/toastify";
import ShipperInputData from "../../data.json";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const LoadDoc = ({ shipperId, shipperLoadId, fetchShipperLoadData }) => {
  const { handleSubmit, control, setValue, reset } = useForm({
    defaultValues: {
      title: "",
      docURL: "",
      description: "",
      fileType: "",
      fileFormat: "",
    },
  });
  const [edit, setEdit] = useState(Boolean);
  const [file, setFiles] = useState(null);
  const [docs, setDocs] = useState([]);
  const [openDrawer, setDrawer] = useState(false);

  const getData = async () => {
    const response = await fetchShipperLoadData();
    setDocs(response?.docs);
  };

  useEffect(() => {
    if (shipperId && shipperLoadId) {
      getData();
    }
  }, [shipperId, shipperLoadId]);

  const [editIndex, setEditIndex] = useState(0);

  const editEntry = (index) => {
    setEdit(true);
    setEditIndex(index);
    setDrawer(true);
    // const obj = allList[index];
    // Object.keys(obj).map((key) => {
    //   if (key === "dimensions") {
    //     Object.keys(obj[key]).map((innerKey) => {
    //       setValue(innerKey, obj[key][innerKey]);
    //     });
    //   }
    //   setValue(key, obj[key]);
    // });
  };

  const columns = [
    {
      id: 1,
      title: "Title",
    },
    {
      id: 2,
      title: "Description",
    },
    {
      id: 2,
      title: "File Type",
    },
    {
      id: 3,
      title: "Action",
    },
  ];

  const submitData = async (values) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("title", values.title);
    formData.append("description", values.description);
    formData.append("fileType", values.fileType);
    formData.append("fileFormat", values.fileFormat);
    const url = `/address/shipper/shipper_files/${shipperId}/load/${shipperLoadId}/docs`;
    try {
      const response = await postData(url, formData, {
        headers: {
          // Make sure to set the correct content type
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status === 200) {
        getData();
        reset();
        setDrawer(false);
        openNotificationWithIcon("success", response?.data?.message);
      }
    } catch (error) {
      openNotificationWithIcon("error", error?.response?.data?.message);
    }
  };

  const updateData = () => {};

  const toggleDrawer = () => {
    setDrawer((prev) => !prev);
  };

  const deleteEntry = (index) => {
    // const updatedFiles = [...files];
    // updatedFiles.splice(index, 1);
    // setFiles(updatedFiles);
  };

  const handleFileInputChange = async (e) => {
    try {
      const file = e.target.files[0];
      setFiles(file);
    } catch (error) {
      console.log({ error });
    }
  };

  const blue = {
    100: "#DAECFF",
    200: "#b6daff",
    400: "#3399FF",
    500: "#007FFF",
    600: "#0072E5",
    900: "#003A75",
  };

  const grey = {
    50: "#f6f8fa",
    100: "#eaeef2",
    200: "#d0d7de",
    300: "#afb8c1",
    400: "#8c959f",
    500: "#6e7781",
    600: "#57606a",
    700: "#424a53",
    800: "#32383f",
    900: "#24292f",
  };

  const StyledTextarea = styled(TextareaAutosize)(
    ({ theme }) => `
        width: 1320px;
        font-family: IBM Plex Sans, sans-serif;
        font-size: 0.875rem;
        font-weight: 400;
        line-H: 1.5;
        padding: 12px;
        border-radius: 12px 12px 0 12px;
        color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
        background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
        border: 1px solid ${
          theme.palette.mode === "dark" ? grey[700] : grey[200]
        };
        box-shadow: 0px 2px 2px ${
          theme.palette.mode === "dark" ? grey[900] : grey[50]
        };
      
        &:hover {
          border-color: ${blue[400]};
        }
      
        &:focus {
          border-color: ${blue[400]};
          box-shadow: 0 0 0 3px ${
            theme.palette.mode === "dark" ? blue[500] : blue[200]
          };
        }
      
        // firefox
        &:focus-visible {
          outline: 0;
        }
      `
  );
  return (
    <Box sx={{ m: 2, p: 2, backgroundColor: "#ffffff", width: "100%" }}>
      <Button
        sx={{
          marginTop: 2,
          marginLeft: 2,
          marginBottom: 2,
          width: "10%",
        }}
        onClick={() => toggleDrawer()}
        variant="contained"
      >
        Add Doc
      </Button>
      <Paper elevation={3} sx={{ m: 4, maxWidth: "90%" }}>
        <TableContainer component={Paper}>
          <Table sx={{ maxWidth: "100%" }} aria-label="customized table">
            <TableHead>
              <TableRow>
                {columns?.map((col) => {
                  return (
                    <TableCell key={`key-${col?.id}`}>{col.title}</TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {docs?.map((row, index) => (
                <StyledTableRow key={row.title}>
                  <StyledTableCell component="th" scope="row">
                    {row.title}
                  </StyledTableCell>
                  <StyledTableCell>{row.description}</StyledTableCell>
                  <StyledTableCell>{row?.fileType}</StyledTableCell>
                  <StyledTableCell>
                    <Grid container spacing={1}>
                      <Grid item>
                        <Button variant="outlined">
                          <EditIcon onClick={() => deleteEntry(row.title)} />
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button variant="outlined">
                          <DeleteIcon onClick={() => deleteEntry(row.title)} />
                        </Button>
                      </Grid>
                    </Grid>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <Drawer
        anchor={"right"}
        open={openDrawer}
        PaperProps={{
          sx: { width: "35%" },
        }}
        onClose={() => toggleDrawer()}
      >
        <Box sx={{ zIndex: 1000000, width: "100%" }} mt={10}>
          <Container component="main" maxWidth="sm">
            <Typography variant="h4">Add Doc</Typography>

            <Box
              container
              component="form"
              onSubmit={
                edit ? handleSubmit(updateData) : handleSubmit(submitData)
              }
              sx={{ mt: 1 }}
            >
              <Controller
                name="title"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    sx={{ marginTop: "10px" }}
                    required
                    fullWidth
                    id="outlined-required"
                    label="Title"
                    variant="outlined"
                    placeholder="Enter Title"
                  />
                )}
              />
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <StyledTextarea
                    {...field}
                    style={{
                      maxWidth: "100%",
                    }}
                    sx={{ marginTop: "10px" }}
                    aria-label="minimum H"
                    minRows={4}
                    // required
                    //
                    // id="outlined-required"
                    // label="Description"
                    // variant="outlined"
                    placeholder="Description"
                  />
                )}
              />

              <Controller
                name="fileType"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    sx={{ marginTop: "10px" }}
                    required
                    fullWidth
                    id="outlined-required"
                    label="File Type"
                    variant="outlined"
                    select
                    // placeholder="Enter Packaging Type"
                  >
                    {ShipperInputData.file_type?.map((option) => {
                      return (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                )}
              />
              <Controller
                name="fileFormat"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    sx={{ marginTop: "10px" }}
                    required
                    fullWidth
                    id="outlined-required"
                    label="File Format"
                    variant="outlined"
                    select
                  >
                    {["Image", "PNG", "Notes"]?.map((option) => {
                      return (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                )}
              />
              <Controller
                name="doc"
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <Box mt={2} mb={3}>
                    <input
                      {...field}
                      type="file"
                      id="file-input"
                      style={{ display: "none" }}
                      onChange={(e) => handleFileInputChange(e)}
                    />
                    <label htmlFor="file-input">
                      <Button
                        component="span"
                        variant="contained"
                        color="primary"
                      >
                        Choose File
                      </Button>
                    </label>
                  </Box>
                )}
              />
              <Button
                type="submit"
                variant="contained"
                sx={{
                  marginTop: "10px",
                  marginLeft: "5px",
                  width: "50%",
                }}
              >
                {edit ? "Update Doc" : "Add Doc"}
              </Button>
            </Box>
          </Container>
        </Box>
      </Drawer>
    </Box>
  );
};

export default LoadDoc;
