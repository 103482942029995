import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, IconButton, Button, Grid } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useEffect, useState} from 'react';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import { v4 as uuidv4 } from 'uuid';

const OtherCharges = ({ otherCharges, setOtherCharges }) => {
    const [rows, setRows] = useState(otherCharges || []);
    const handleInputChange = (index, event) => {
        const { name, value } = event.target;
        const newRows = [...rows];
        newRows[index] = { ...newRows[index], [name]: value };
        setRows(newRows);
    };
    useEffect(() => {
     setRows(otherCharges)
    }, [otherCharges])
    
    const areObjectsEqual = (obj1 = {}, obj2 = {}) => {
  const entries1 = Object.entries(obj1);
  const entries2 = Object.entries(obj2);

  if (entries1.length !== entries2.length) {
    return false;
  }

  return entries1.every(([key, value]) =>
    obj2.hasOwnProperty(key) && obj2[key] === value
  );
}

    const handleAdd = (id) => {
        const row = rows.find(row => row.id === id)
        const isNotSame = !areObjectsEqual(row, otherCharges?.find(i => i.id === id))
        if (checkForValues(row) && isNotSame) {
            setOtherCharges([ ...rows])
        }
        return
        // setRows();
    };

    const checkForValues = (row) => {
        
        return row.amount && row.type
    }

    const handleDelete = (id) => {
        setRows(rows.filter(row => row.id !== id));
        setOtherCharges(rows.filter(row => row.id !== id))
    };

    const handleAddRow = () => {
        const newId = uuidv4();
        setRows([
            ...rows,
            { id: newId, type: '', amount: '' }
        ]);
    };
    return (
        <>
            {
                (rows?.length > 0) ?
                    <>
                        <TableContainer component={Paper}>
                            <Table>
                                {(rows?.length > 0) ?
                                    <>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ padding: '4px 8px' }} >Type</TableCell>
                                                <TableCell style={{ padding: '4px 8px' }} >Amount</TableCell>
                                                <TableCell style={{ padding: '4px 8px' }} >
                                                    <IconButton
                                                    color="info"
                                                    onClick={handleAddRow}
                                                    aria-label="add"
                                                >
                                                    <AddIcon />
                                                </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead></>
                                    : ''
                                }

                                <TableBody>
                                    {rows?.map((row, index) => (
                                        <TableRow key={row.id} style={{ padding: '4px 8px' }}>
                                            <TableCell style={{ padding: '4px 8px' }} >
                                                <TextField
                                                    size="small"
                                                    variant="outlined"
                                                    name="type"
                                                    value={row.type}
                                                    onChange={(e) => handleInputChange(index, e)}
                                                    fullWidth
                                                    InputProps={{
                                                        style: { padding: '0px' }, // No padding around the input
                                                        inputProps: {
                                                            style: { padding: '4px 8px', fontSize: '15px' } // Padding inside the input
                                                        }
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell style={{ padding: '4px 8px' }} >
                                                <TextField
                                                    size="small"
                                                    variant="outlined"
                                                    name="amount"
                                                    value={row.amount}
                                                    onChange={(e) => handleInputChange(index, e)}
                                                    fullWidth
                                                    InputProps={{
                                                        style: { padding: '0px' }, // No padding around the input
                                                        inputProps: {
                                                            style: { padding: '4px 8px', fontSize: '15px' }
                                                        }
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <IconButton
                                                    color="info"
                                                    onClick={() => handleAdd(row.id)}
                                                    aria-label="add"
                                                >
                                                    <CheckIcon />
                                                </IconButton>
                                                <IconButton
                                                    color="error"
                                                    onClick={() => handleDelete(row.id)}
                                                    aria-label="delete"
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                                
                                            </TableCell>
                                            
                                        </TableRow>
                                    ))}
                                    <TableRow>
                                        <TableCell style={{ padding: '4px 8px' }} ></TableCell>
                                        <TableCell style={{ padding: '4px 8px' }} ></TableCell>
                                        <TableCell style={{ padding: '4px 8px' }} >
                                            <Button variant="contained" color="primary" onClick={handleAddRow}>
                                                Add More
                                            </Button>
                                        </TableCell>

                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </> : <>
                        <Button variant="contained" color="primary" onClick={handleAddRow}>
                            Add other charges
                        </Button>
                    </>
            }

        </>
    );
};

export default OtherCharges;