import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Breadcrumbs, Button } from "@mui/material";
import { getData } from "../services/axiosInstance";
import moment from "moment";
import AuthLayout from "../components/Auth_Layout/AuthLayout";
import { Link, useNavigate } from "react-router-dom";
import DOTModal from "../components/DOTModal/DOTModal";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#1976d2',
    color: theme.palette.common.white,
    fontSize: 13,
    padding:10,
    textAlign:'center'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    textTransform: "capitalize",
    padding:10,
    textAlign:'center'
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function Carriers() {
  const [listData, setListData] = useState([]);
  const navigate = useNavigate();
  const [openAddModal, setOpenAddModal] = useState(false);
  async function fetchData() {
    const url = `/address/carrier/view`;
    try {
      const response = await getData(url);
      if (response.status === 200) {
        setListData(response?.data?.data);
        // setListData(array)
      }
      //setListData([{_id: 1, DOTNumber : "102"},{_id: 2, DOTNumber : "102"}, ])
    } catch (error) {
      console.log({ error });
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      id: 1,
      title: "Name",
    },
    {
      id: 2,
      title: "DOT number",
    },
    {
      id: 3,
      title: "MC number",
    },
    {
      id: 4,
      title: "Created At",
    },
    {
      id: 5,
      title: "Action",
    },
  ];

  const openModalFunction = () => {
    setOpenAddModal(true);
  };
  const closeModalFunction = (state) => {
    setOpenAddModal(false);
    if (state?.refetch) {
      fetchData();
    }
  };

  return (
    <AuthLayout>
      <Box mt={9} ml={2} mr={2} sx={{ width: "100%" }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Breadcrumbs aria-label="breadcrumb" mt={4} mb={2}>
            <Link underline="hover" color="inherit" href="/Shipper">
              Carriers
            </Link>
            <Typography color="text.primary">List</Typography>
          </Breadcrumbs>
          <Button onClick={() => openModalFunction()} variant="contained">
            Add
          </Button>
        </Box>

        <Paper elevation={3}>
          <TableContainer>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  {columns?.map((col) => {
                    return (
                      <StyledTableCell key={`key-${col.id}`} align={col.id ===1  ? "left": "center"}>
                        {col.title}
                      </StyledTableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {listData?.map((row) => (
                  <StyledTableRow key={row._id}>
                    <StyledTableCell component="th" scope="row">
                      {row?.allData?.LegalName}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.dotNumber}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.mcNumber}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {moment(row?.createdAt).format("YYYY-MM-DD HH:mm")}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Button
                        onClick={() => navigate(`/carrier-info/${row._id}`)}
                        variant="contained"
                           size="small"
                      >
                        View
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
            {/* <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={listData.length}
              rowsPerPage={1}
              page={5}
              // onPageChange={handleChangePage}
              // onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
          </TableContainer>
        </Paper>
      </Box>
      <DOTModal
        openAddModal={openAddModal}
        closeModalFunction={closeModalFunction}
        // shipperId={shipperId}
        // shipperLoadId={shipperLoadId}
      />
    </AuthLayout>
  );
}
