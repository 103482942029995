import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import {
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { postData } from "../../services/axiosInstance";
import { useNavigate } from "react-router-dom";
import { openNotificationWithIcon } from "../Layout/Toastify/toastify";
import ShipperInputData from "../../data.json";
import OtherCharges from "../LoadDoc/otherCharges";

export default function ShipperDetails({
  fetchShipperLoadData,
  shipperId,
  shipperLoadId,
  ShipperData,
}) {
  const [shipperDetail, setShipperDetail] = useState(null);

  const navigate = useNavigate();

  const defaultValues = {
    rate: "",
    rateType: "",
    paymentMethod: "",
    loadType: "",
    modeOfService: "",
    typeOfEquipment: "",
    size: "",
    teamDriverLoad: "no",
    isPrepaid: false,
    otherCharges: []
  };
  const [formValues, setFormValues] = useState(defaultValues);

  const { control, handleSubmit, setValue } = useForm({
    // resolver: yupResolver(schema),
    defaultValues: formValues,
  });

  useEffect(() => {
    const getData = async () => {
      const response = await fetchShipperLoadData();
      setShipperDetail(response);
      Object.keys(response).forEach((key) => {
        setValue(key, response[key]);
      });
    };
    if (shipperId && shipperLoadId) {
      getData();
    }
  }, [shipperId, shipperLoadId]);

  const onSubmitShipperHandler = async (values) => {
    const payload = {
      ...values,
      shipperId,
      shipTag: ShipperData?.shipTag,
    };

    const url = shipperLoadId
      ? `/address/shipper/loads/${shipperId}/load/${shipperLoadId}`
      : `/address/shipper/loads/${shipperId}/add`;
    try {
      const response = await postData(url, payload);
      if (response.status === 200) {
        let id = "";
        if (shipperLoadId) {
          id = shipperLoadId;
        } else {
          id = response.data.data.shipperLoadId._id;
        }
        openNotificationWithIcon("success", response?.data?.message);
        navigate(`/shipper/${shipperId}/load/${id}`);
      }
    } catch (error) {
      alert("error");
      // setDisabled(false);
      openNotificationWithIcon("error", error?.response?.data?.message);
    }
  };

  const setOtherCharges = (charges) => {
    setValue('otherCharges',charges )
  }

  return (
    <Card
      variant="outlined"
      sx={{
        marginLeft: 5,
        // width: "90%",
        // marginBottom: 5,
        padding: "10px 0px",
        background: "aliceblue",
      }}
    >
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmitShipperHandler)}
        pl={1}
        pt={5}
        pb={5}
        sx={{
          "& .MuiTextField-root": { m: 1, width: "25ch" },
          // background: "#fff",
          width: "100%",
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <Grid container spacing={1} ml={2} sx={{ width: "100%" }}>
            <Grid item xs={2.5}>
              <Controller
                name="rateType"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    id="outlined-select-currency"
                    label="Rate Type"
                    variant="outlined"
                    placeholder="Enter user Name"
                    select
                  >
                    {ShipperInputData?.rate_type?.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={2.5}>
              <Controller
                name="rate"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    id="outlined-required"
                    label="Rate"
                    variant="outlined"
                    placeholder="Enter Rate"
                  />
                )}
              />
            </Grid>
            {/* <Grid item xs={2.5}></Grid> */}
          </Grid>
        </div>
        <div>
          <Grid container spacing={1} ml={3} mt={3} mb={4} sx={{ width: "100%" }}>
            <Grid item >
              <OtherCharges  otherCharges={shipperDetail?.otherCharges} setOtherCharges={setOtherCharges} />
            </Grid>
          </Grid>
        </div>

        <div>
          <Grid container spacing={1} ml={2} sx={{ width: "100%" }}>
            <Grid item xs={2.5}>
              <Controller
                name="loadType"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    id="outlined-select-currency"
                    variant="outlined"
                    select
                    label="Load Type"
                  >
                    {ShipperInputData?.load_type?.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={2.5}>
              <Controller
                name="modeOfService"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    id="outlined-select-currency"
                    variant="outlined"
                    select
                    label="Mode of Service"
                  >
                    {ShipperInputData?.mode_of_service?.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={2.5}>
              <Controller
                name="typeOfEquipment"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    id="outlined-select-currency"
                    variant="outlined"
                    select
                    label="Type of Equipment"
                  >
                    {ShipperInputData?.equipments_type?.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={2.5}>
              <Controller
                name="size"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    id="outlined-required"
                    label="Size"
                    variant="outlined"
                    placeholder="Enter Size"
                  />
                )}
              />
            </Grid>
          </Grid>
        </div>
        <div>
          <Grid container spacing={1} ml={2} sx={{ width: "100%" }}>
            <Grid item xs={2.5}>
              <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                <FormLabel component="legend">Team Driver Load</FormLabel>
                <Controller
                  name="teamDriverLoad"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <RadioGroup {...field} value={field.value} >
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2.5} mt={2}>
              <FormGroup>
                <Controller
                  name="isPrepaid"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} checked={field.value} />}
                      label="Is Prepaid"
                    />
                  )}
                />
              </FormGroup>
            </Grid>
            {/* <Grid item xs={2.5}></Grid> */}

          </Grid>
        </div>
        <Button
          variant="contained"
          sx={{
            marginTop: "10px",
            marginLeft: "40px",
            width: "20%",
          }}
          type="submit"
        >
          {shipperLoadId ? "Update" : "Save"}
        </Button>
      </Box>
    </Card>
  );
}
