import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import ShipperDetails from "../../ShipperDetails/ShipperDetails";
import Consignment from "../../Consignment/Consignment";
import LoadDoc from "../../LoadDoc/LoadDoc";
import Instructions from "../../LoadDoc/Instructions";
import LoadCarrier from "../../LoadCarrier/LoadCarrier";
import PdfGenerate from "../../PdfGenerate/PdfGenerate";
import LoadStatus from "../../LoadDoc/loadStatus";
export default function LoadPageTabs({
  loadId,
  fetchLoadData,
  shipperId,
  ShipperData,
  shipperLoadData,
}) {
  const [value, setValue] = React.useState("shipper");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ typography: "body1" }} mt={2}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "transparent" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab
              sx={{ color: "#acacac" }}
              label="Shipper Details"
              value="shipper"
            />
            <Tab
              sx={{ color: "#acacac" }}
              label="Consignment"
              value="consignment"
              disabled={loadId ? false : true}
            />
            <Tab
              sx={{ color: "#acacac" }}
              label="Docs"
              value="docs"
              disabled={loadId ? false : true}
            />
            <Tab
              sx={{ color: "#acacac" }}
              label="Instructions"
              value="instructions"
              disabled={loadId ? false : true}
            />
            <Tab
              sx={{ color: "#acacac" }}
              label="Load Carrier"
              value="loadCarrier"
              disabled={loadId ? false : true}
            />
            <Tab
              sx={{ color: "#acacac" }}
              label="Generate PDF"
              value="PdfGenerate"
              disabled={loadId ? false : true}
            />
            <Tab
              sx={{ color: "#acacac" }}
              label="Load Status"
              value="loadStatus"
              disabled={loadId ? false : true}
            />
          </TabList>
        </Box>
        <TabPanel sx={{ paddingLeft: "0px", height: "527px" }} value="shipper">
          <ShipperDetails
            fetchShipperLoadData={fetchLoadData}
            shipperId={shipperId}
            shipperLoadId={loadId}
            ShipperData={ShipperData}
            shipperLoadData={shipperLoadData}
          />
        </TabPanel>
        <TabPanel
          sx={{ paddingLeft: "0px", width: "100%" }}
          value="consignment"
        >
          <Consignment
            fetchShipperLoadData={fetchLoadData}
            shipperId={shipperId}
            shipperLoadId={loadId}
            ShipperData={ShipperData}
            shipperLoadData={shipperLoadData}
          />
        </TabPanel>
        <TabPanel
          sx={{ paddingLeft: "0px", height: "527px", width: "100%" }}
          value="docs"
        >
          <LoadDoc
            fetchShipperLoadData={fetchLoadData}
            shipperId={shipperId}
            shipperLoadId={loadId}
            ShipperData={ShipperData}
            shipperLoadData={shipperLoadData}
          />
        </TabPanel>
        <TabPanel
          sx={{ paddingLeft: "0px", height: "527px", width: "100%" }}
          value="instructions"
        >
          <Instructions
            fetchShipperLoadData={fetchLoadData}
            shipperId={shipperId}
            shipperLoadId={loadId}
            ShipperData={ShipperData}
            shipperLoadData={shipperLoadData}
          />
        </TabPanel>
        <TabPanel
          sx={{ paddingLeft: "0px", height: "527px", width: "100%" }}
          value="loadCarrier"
        >
          <LoadCarrier
            fetchShipperLoadData={fetchLoadData}
            shipperId={shipperId}
            shipperLoadId={loadId}
            ShipperData={ShipperData}
            shipperLoadData={shipperLoadData}
          />
        </TabPanel>
        <TabPanel
          sx={{ paddingLeft: "0px", height: "527px", width: "100%" }}
          value="PdfGenerate"
        >
          <PdfGenerate
            fetchShipperLoadData={fetchLoadData}
            shipperId={shipperId}
            shipperLoadId={loadId}
            ShipperData={ShipperData}
            shipperLoadData={shipperLoadData}
          />
        </TabPanel>
        <TabPanel
          sx={{ paddingLeft: "0px", height: "527px", width: "100%" }}
          value="loadStatus"
        >
          <LoadStatus
            fetchShipperLoadData={fetchLoadData}
            shipperId={shipperId}
            shipperLoadId={loadId}
            ShipperData={ShipperData}
            shipperLoadData={shipperLoadData}
          />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
