import * as Yup from "yup";

const shipperValidation = Yup.object().shape({
  companyName: Yup.string()
    .required("This field is required")
    .min(5, "Minimum 5 characters")
    .max(30, "Maximum 30 characters"),
    // .matches(/^[^\s].*[^\s]$/, "Empty spaces are not allowed"),
  doingBusiness: Yup.string()
    .required("This field is required")
    .max(30, "Maximum 30 characters"),
    // .matches(/^[^\s].*[^\s]$/, "Empty spaces are not allowed"),
  refName: Yup.string().required("This field is required")
    .max(30, "Maximum 30 characters"),
  industryType: Yup.string().required("This field is required")

    .max(30, "Maximum 30 characters"),
  country: Yup.string().required("This field is required"),
  zip: Yup.string().required("This field is required"),
  // website: Yup.string().required("This field is required"),
  phone: Yup.string()
    .matches(/^\d+$/, "Only numeric values are allowed")
    .min(10, "Minimum 10 characters")
    .max(10, "Maximum 10 characters"),
  fax: Yup.string()
    .matches(/^\d+$/, "Only numeric values are allowed")
    .min(9, "Minimum 9 characters")
    .max(10, "Maximum 9 characters"),
  email: Yup.string()
    .required("Email is required")
    .trim()
    .email("Please enter the valid email ID")
    .min(5, "Minimum 5 characters")
    .max(30, "Maximum 30 characters"),
  fedTaxId: Yup.string()
    .matches(/^\d+$/, "Only numeric values are allowed")
    .min(9, "Minimum 9 characters")
    .max(10, "Maximum 9 characters"),
  typeOfBusiness: Yup.string().required("This field is required"),
  yearsInBusiness: Yup.string().required("This field is required"),
  location: Yup.string().required("This field is required"),
  incorporationState: Yup.string().required("This field is required"),
});

const contactDetailValidation = Yup.object().shape({
  managerFirstName: Yup.string().required("Manager First Name is required"),
  managerLastName: Yup.string().required("Manager Last Name is required"),
  managerEmail: Yup.string()
    .email("Invalid email format")
    .required("Manager Email is required"),
  managerPhone: Yup.string()
    .matches(/^\d{10}$/, "Phone number must be 10 digits")
    .required("Manager Phone is required"),
  ownerFirstName: Yup.string().required("Owner First Name is required"),
  ownerLastName: Yup.string().required("Owner Last Name is required"),
  ownerEmail: Yup.string()
    .email("Invalid email format")
    .required("Owner Email is required"),
  ownerPhone: Yup.string()
    .matches(/^\d{10}$/, "Phone number must be 10 digits")
    .required("Owner Phone is required"),
  ownerStreetAddress: Yup.string().required("Owner Street Address is required"),
  ownerCountry: Yup.string().required("Owner Country is required"),
  ownerState: Yup.string().required("Owner State is required"),
  ownerCity: Yup.string().required("Owner City is required"),
  ownerZip: Yup.string().required("Owner Zip is required"),
});

const shipperPaymentValidation = Yup.object().shape({
  paymentTerms: Yup.string().required("This field is required"),
  paymentMethod: Yup.string().required("This field is required"),
  creditLimitRequested: Yup.string().required("This field is required"),
});

export { shipperValidation, contactDetailValidation, shipperPaymentValidation };
