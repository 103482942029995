import React from "react";

import {
  Card,
  CardContent,
  Typography,
  Box,
  TableRow,
  Paper,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { styled } from "@mui/material/styles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const CommoditiesDetails = ({ shipperData }) => {
  return (
    shipperData && (
      <Card>
        <CardContent>
          <Typography variant="h6" color="primary">
            Commodities Details
          </Typography>
          <Box mt={2}>
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: "100%" }}
                size={"small"}
                aria-label="table"
              >
                <TableBody>
              
                  <StyledTableRow>
                    <StyledTableCell align="left">
                      <strong>Is consigner:</strong>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {shipperData?.commoditiesDetails?.isConsigner
                        ? "Yes"
                        : "No"}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left">
                      <strong>Is consignee:</strong>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {shipperData?.commoditiesDetails?.isConsignee
                        ? "Yes"
                        : "No"}
                    </StyledTableCell>
                  </StyledTableRow>

                  {shipperData?.commoditiesDetails?.segments?.map(
                    (segment, index) => (
                      <>
                        <StyledTableRow>
                          <StyledTableCell align="left">
                            <strong>Commodity {index + 1}:</strong>
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {segment?.commodity}
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledTableCell align="left">
                            <strong>Commodity Type:</strong>
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {segment?.commodityType}
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledTableCell align="left">
                            <strong>Packaging Type:</strong>
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {segment?.packagingType}
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledTableCell align="left">
                            <strong>Dimensions:</strong>
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            L:{segment?.dimensions?.L} W:
                            {segment?.dimensions?.W} H:{segment?.dimensions?.H}
                          </StyledTableCell>
                        </StyledTableRow>
                      </>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </CardContent>
      </Card>
    )
  );
};

export default CommoditiesDetails;
