import * as Yup from "yup";

const LOGIN_VALIDATION_SCHEMA = Yup.object().shape({
  email: Yup.string()
    .required("Email is required")
    .trim()
    .email("Please enter the valid email ID"),
  // password: Yup.string()
  //   .matches(
  //     /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])/,
  //     "Uppercase, Lowecase, Special Character, Numeric"
  //   )
  //   .min(8, "Password should be atleast 8 characters")
  //   .max(30, "Password cannot exceed more than 30 characters")
  //   .trim()
  //   .label("Password")
  //   .required("Wrong password! Please enter the correct password"),
});

export { LOGIN_VALIDATION_SCHEMA };
