import React, { Fragment } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { Provider } from "react-redux";
import { store, persistor } from "./store/store";
import { PersistGate } from "redux-persist/integration/react";
import Login from "./pages/Login";
import Shipper from "./pages/Shipper";
import PrivateRoute from "./routes/PrivateRoutes";
import AddShipper from "./pages/AddShipper/AddShipper";
import Users from "./pages/Users";
import AllLoads from "./pages/AllLoads";
import AddUser from "./pages/AddUser";
import Dashboard from "./pages/Dashboard";
import AddLoad from "./pages/Load/Load";
import ShipperDetail from "./pages/ShipperDetail";
import Carriers from "./pages/Carriers";
import CarrierDetail from "./pages/CarrierDetail";
import Workspaces from "./pages/Workspaces";
import AddWorkspace from "./pages/AddWorkspace";
import CarrierInfo from "./pages/CarrierInfo";
import UserProfile from "./pages/UserProfile";
import { api } from "./services/axiosInstance";

const App = () => {
  const navigate = useNavigate();
  api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        navigate("/login");
      }
      return Promise.reject(error);
    }
  );
  api.interceptors.request.use(
    (config) => {
      console.log({ config });
      // Do something before request is sent, like adding headers
      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  return (
    <Fragment>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Routes>
            {/* DOne */}
            <Route exact path="/" element={<PrivateRoute />}>
              {/* for the end */}
              <Route exact path="/" element={<Dashboard />} />
              {/* Done */}
              <Route path="/shipper" element={<Shipper />} />
              <Route path="/shipper/:shipperId" element={<ShipperDetail />} />

              <Route path="/addShipper" element={<AddShipper />} />
              <Route path="/users" element={<Users />} />
              <Route path="/users/edit/:id" element={<AddUser />} />
              <Route path="/workspaces" element={<Workspaces />} />
              <Route path="/workspaces/add" element={<AddWorkspace />} />
              <Route path="/loads" element={<AllLoads />} />
              <Route path="/users/add" element={<AddUser />} />
              <Route path="/shipper/:shipperId/load" element={<AddLoad />} />
              <Route path="/shipper/edit/:shipperId" element={<AddShipper />} />
              <Route
                path="/shipper/:shipperId/load/:shipperLoadId"
                element={<AddLoad />}
              />
              <Route
                path="/shipper/:shipperId/load/:shipperLoadId"
                element={<AddLoad />}
              />
              <Route path="/carriers" element={<Carriers />} />
              <Route path="/profile" element={<UserProfile />} />
              <Route path="/carrier/:carrierId" element={<CarrierDetail />} />
              <Route
                path="/carrier-info/:carrierId"
                element={<CarrierInfo />}
              />
            </Route>
            <Route exact path="/login" element={<Login />} />
          </Routes>
        </PersistGate>
      </Provider>
    </Fragment>
  );
};

export default App;
