import React from "react";

import { Card, CardContent, Typography, Box } from "@mui/material";

const DescriptionDetails = ({ shipperData }) => {
  return (
    shipperData && (
      <Card>
        <CardContent>
          <Typography variant="h6" color="primary">
            {" "}
            Instructions
          </Typography>
          <Box mt={2}>{shipperData?.commoditiesDetails?.instructions}</Box>
        </CardContent>
      </Card>
    )
  );
};

export default DescriptionDetails;
