import {
  Box,
  Button,
  Container,
  Drawer,
  Grid,
  TextField,
  TextareaAutosize,
  TableRow,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  MenuItem,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Typography } from "antd";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { useState, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { postData } from "../../services/axiosInstance";
import { openNotificationWithIcon } from "../Layout/Toastify/toastify";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import shiperInputData from "../../data.json";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function Commodities({
  shipperId,
  fetchShipperData,
  ShipperData,
}) {
  const [openDrawer, setDrawer] = useState(false);
  const [allList, setAllList] = useState(
    ShipperData?.commoditiesDetails?.segments ?? []
  );
  const [edit, setEdit] = useState(Boolean);
  const [editIndex, setEditIndex] = useState(0);
  const instructionRef = useRef(null);
  const consignerRef = useRef(null);
  const consigneeRef = useRef(null);

  const { control, handleSubmit, reset, setValue } = useForm({
    // resolver: yupResolver(schema),
    defaultValues: {
      commodity: "",
      commodityType: "",
      packagingType: "",
      L: "",
      W: "",
      H: "",
    },
  });

  const submitData = (values) => {
    const newObject = {
      commodity: values.commodity,
      commodityType: values.commodityType,
      packagingType: values.packagingType,
      dimensions: {
        L: values.L,
        W: values.W,
        H: values.H,
      },
    };
    const newArray = [...allList, newObject];
    setAllList(newArray);

    reset();
    setDrawer(false);
  };
  const updateData = (values) => {
    const updatedList = [...allList];
    updatedList[editIndex] = {
      ...updatedList[editIndex],
      commodity: values.commodity,
      commodityType: values.commodityType,
      packagingType: values.packagingType,
      dimensions: {
        L: values.L,
        W: values.W,
        H: values.H,
      },
    };
    setAllList(updatedList);
    setEdit(false);
    setEditIndex(0);
    setDrawer(false);
    reset();
  };
  const deleteEntry = (id) => {
    const updatedList = allList.filter((element, index) => index !== id);
    setAllList(updatedList);
  };
  const editEntry = (index) => {
    setEdit(true);
    setEditIndex(index);
    setDrawer(true);
    const obj = allList[index];
    Object.keys(obj).map((key) => {
      if (key === "dimensions") {
        Object.keys(obj[key]).map((innerKey) => {
          setValue(innerKey, obj[key][innerKey]);
        });
      }
      setValue(key, obj[key]);
    });
  };

  const toggleDrawer = () => {
    setDrawer((prev) => !prev);
  };
  const blue = {
    100: "#DAECFF",
    200: "#b6daff",
    400: "#3399FF",
    500: "#007FFF",
    600: "#0072E5",
    900: "#003A75",
  };

  const grey = {
    50: "#f6f8fa",
    100: "#eaeef2",
    200: "#d0d7de",
    300: "#afb8c1",
    400: "#8c959f",
    500: "#6e7781",
    600: "#57606a",
    700: "#424a53",
    800: "#32383f",
    900: "#24292f",
  };

  const StyledTextarea = styled(TextareaAutosize)(
    ({ theme }) => `
        width: 1320px;
        font-family: IBM Plex Sans, sans-serif;
        font-size: 0.875rem;
        font-weight: 400;
        line-H: 1.5;
        padding: 12px;
        border-radius: 12px 12px 0 12px;
        color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
        background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
        border: 1px solid ${
          theme.palette.mode === "dark" ? grey[700] : grey[200]
        };
        box-shadow: 0px 2px 2px ${
          theme.palette.mode === "dark" ? grey[900] : grey[50]
        };
      
        &:hover {
          border-color: ${blue[400]};
        }
      
        &:focus {
          border-color: ${blue[400]};
          box-shadow: 0 0 0 3px ${
            theme.palette.mode === "dark" ? blue[500] : blue[200]
          };
        }
      
        // firefox
        &:focus-visible {
          outline: 0;
        }
      `
  );

  const onSubmit = async () => {
    let result = {};

    result.instructions = instructionRef.current.value;
    result.segments = allList;
    result.isConsignee = consigneeRef.current.checked;
    result.isConsigner = consignerRef.current.checked;
    const payload = result;

    const url = `/address/shipper/commoditiesDetails/${shipperId}`;
    try {
      const response = await postData(url, payload);
      if (response.status === 200) {
        openNotificationWithIcon("success", response?.data?.message);
        fetchShipperData();
      }
    } catch (error) {
      openNotificationWithIcon("error", "something went wrong");
    }
  };
  const columns = [
    {
      id: 1,
      title: "Commodity",
    },
    {
      id: 2,
      title: "Type of commodity",
    },
    {
      id: 3,
      title: "Packaging Type",
    },
    {
      id: 4,
      title: "Dimensions",
    },
    {
      id: 5,
      title: "Action",
    },
  ];

  return (
    <Box
      pl={1}
      pt={5}
      pb={5}
      sx={{
        background: "#fff",
        width: "100%",
      }}
    >
      <div
        style={{
          marginLeft: 40,
          marginBottom: 10,
        }}
      >
        Standing Instruction
      </div>
      <div
        style={{
          marginLeft: 40,
          marginBottom: 10,
        }}
      >
        <StyledTextarea
          ref={instructionRef}
          defaultValue={ShipperData?.commoditiesDetails?.instructions}
          aria-label="minimum H"
          minRows={4}
          placeholder="Default Textarea"
          // value={instruction}
        />
      </div>
      <div>
        <Paper elevation={3} sx={{ m: 4, maxWidth: "100%" }}>
          <TableContainer component={Paper}>
            <Button
              sx={{
                marginTop: 2,
                marginLeft: 2,
                marginBottom: 2,
                width: "50%",
              }}
              onClick={() => toggleDrawer()}
              variant="contained"
            >
              Add Commodity
            </Button>
            <Table sx={{ maxWidth: "100%" }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  {columns?.map((col) => {
                    return (
                      <TableCell key={`key-${col?.id}`}>{col.title}</TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {allList?.map((row, index) => (
                  <StyledTableRow key={row._id}>
                    <StyledTableCell component="th" scope="row">
                      {row.commodity}
                    </StyledTableCell>
                    <StyledTableCell>{row.commodityType}</StyledTableCell>
                    <StyledTableCell>{row.packagingType}</StyledTableCell>
                    <StyledTableCell>{`L : ${row.dimensions.L} W : ${row.dimensions.W} H : ${row.dimensions.H}`}</StyledTableCell>
                    <StyledTableCell>
                      <Button variant="contained">
                        <ModeEditIcon onClick={() => editEntry(index)} />
                      </Button>
                      <Button variant="outlined">
                        <DeleteIcon onClick={() => deleteEntry(index)} />
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <Grid item xs={2.5} mt={2.5} display="flex" justifyContent="center">
          <FormControl component="fieldset">
            <>
              <FormGroup
                aria-label="position"
                row
                sx={{ marginLeft: 0, marginTop: 0 }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      inputRef={consignerRef}
                      defaultChecked={
                        ShipperData?.commoditiesDetails?.isConsigner
                      }
                    />
                  }
                  label="Add as consigner"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      inputRef={consigneeRef}
                      defaultChecked={
                        ShipperData?.commoditiesDetails?.isConsignee
                      }
                    />
                  }
                  label="Add as consignee"
                />
              </FormGroup>
            </>
          </FormControl>
        </Grid>
        <Button
          onClick={() => onSubmit()}
          variant="contained"
          sx={{
            marginTop: "10px",
            marginLeft: "40px",
            width: "20%",
          }}
        >
          Update
        </Button>
      </div>
      <Drawer
        anchor={"right"}
        open={openDrawer}
        PaperProps={{
          sx: { width: "35%" },
        }}
        onClose={() => toggleDrawer()}
      >
        <Box sx={{ zIndex: 1000000, width: "100%" }} mt={10}>
          <Container component="main" maxWidth="sm">
            <Typography variant="h1" component="h2">
              Add Commodities
            </Typography>

            <Box
              component="form"
              onSubmit={
                edit ? handleSubmit(updateData) : handleSubmit(submitData)
              }
              sx={{ mt: 1 }}
            >
              <Controller
                name="commodity"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    sx={{ marginTop: "10px" }}
                    required
                    fullWidth
                    id="outlined-required"
                    label="Commodity"
                    variant="filled"
                    placeholder="Enter Commodity"
                  />
                )}
              />
              <Controller
                name="commodityType"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    sx={{ marginTop: "10px" }}
                    required
                    fullWidth
                    id="outlined-required"
                    label="Type of Commodity"
                    variant="filled"
                    placeholder="Enter Commodity Type"
                  />
                )}
              />

              <Controller
                name="packagingType"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    sx={{ marginTop: "10px" }}
                    required
                    fullWidth
                    id="outlined-required"
                    label="Packaging Type"
                    variant="filled"
                    select
                    placeholder="Enter Packaging Type"
                  >
                    {shiperInputData?.packaging_types?.map((option) => {
                      return (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                )}
              />
              <Controller
                name="L"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    sx={{ marginTop: "10px" }}
                    required
                    fullWidth
                    id="outlined-required"
                    label="Length"
                    variant="filled"
                    placeholder="Length"
                  />
                )}
              />
              <Controller
                name="W"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    sx={{ marginTop: "10px" }}
                    required
                    fullWidth
                    id="outlined-required"
                    label="Width"
                    variant="filled"
                    placeholder="Width"
                  />
                )}
              />
              <Controller
                name="H"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    sx={{ marginTop: "10px" }}
                    required
                    fullWidth
                    id="outlined-required"
                    label="Height"
                    variant="filled"
                    placeholder="Height"
                  />
                )}
              />
              <Button
                type="submit"
                variant="contained"
                sx={{
                  marginTop: "10px",
                  marginLeft: "5px",
                  width: "50%",
                }}
              >
                {edit ? "Update" : "Add"}
              </Button>
            </Box>
          </Container>
        </Box>
      </Drawer>
    </Box>
  );
}
