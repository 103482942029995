import {
  Card,
  Grid,
  Box,
  TableContainer,
  Table,
  TableHead,
  Paper,
  TableBody,
  TableRow,

  styled,
  Button,
} from "@mui/material";
import React from "react";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const CommodityTable = ({
  dataArray,
  deleteEntry,
  type,
  addressIndex,
  editEntry,
}) => {
  const columns = [
    {
      id: 1,
      title: "Commodity",
    },
    {
      id: 2,
      title: "Description",
    },
    {
      id: 3,
      title: "PO Number",
    },
    {
      id: 4,
      title: "PickUp Details",
    },
    {
      id: 5,
      title: "Action",
    },
  ];
  return (
    <Card
      variant="outlined"
      sx={{
        marginLeft: 5,
        width: "90%",
        marginBottom: 5,
        padding: "10px 0px",
       
      }}
    >
      <Grid container>
        <Box mt={2} sx={{  width: "100%" }}>
          <Paper elevation={3} sx={{ m: 4, maxWidth: "100%" }}>
            <TableContainer component={Paper}>
              <Table sx={{ maxWidth: "100%" }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    {columns?.map((col) => {
                      return (
                        <TableCell key={`key-${col?.id}`}>
                          {col.title}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataArray?.map((row, index) => (
                    <StyledTableRow key={row.commodity}>
                      <StyledTableCell component="th" scope="row">
                        {row.commodity}
                      </StyledTableCell>
                      <StyledTableCell>
                        <p>Units: {row.units}</p>
                        <p>Weight: {row.weight}</p>
                        <p>Value: {row.value}</p>
                      </StyledTableCell>
                      <StyledTableCell>{row.poNumber}</StyledTableCell>
                      <StyledTableCell>
                        <p>Date: {row.pickupDate}</p>
                        <p>
                          Time (<small>From</small>): {row.pickupTimeFrom}
                        </p>
                        <p>
                          Time (<small>To</small>) {row.pickupTimeTo}
                        </p>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Grid container spacing={1}>
                          <Grid item>
                            <Button variant="outlined">
                              <EditIcon
                                onClick={() =>
                                  editEntry(row, index, type, addressIndex)
                                }
                              />
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button variant="outlined">
                              <DeleteIcon
                                onClick={() =>
                                  deleteEntry(row, index, type, addressIndex)
                                }
                              />
                            </Button>
                          </Grid>
                        </Grid>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </Grid>
    </Card>
  );
};

export default CommodityTable;
